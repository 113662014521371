 /* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AWS, Auth, Storage, Cache } from 'aws-amplify';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { lighten, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import { DropzoneArea } from 'material-ui-dropzone'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as utils from '../functions/util.js';
import TransferList from '../parts/transferList.js'
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import SnackMessage from '../parts/SnackMessage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import videoPlayer from '../parts/videoPlayer/VideoPlayer.jsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import queryString from 'query-string'
import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmNavigationModal from "./confirmNavigationModal";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate, } from 'react-day-picker/moment';
import Uploader from '../parts/Uploader.js'
//AWS.config.httpOptions.timeout = 0;


const styles = {
  form: {
    margin: 30,
    color: '#fff'
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
  datePicker: {
    color: 'black'
  },


}

const BorderLinearProgress = withStyles({
  root: {
    height: 20,
    backgroundColor: '#373737',
  },
  bar: {
    backgroundColor: '#83d042',
  },
})(LinearProgress);

const CssTextField = withStyles({
  root: {

    '& label': {
      color: '#fff',

    },
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:before': {
      //borderBottom:'5px solid #E20074'
      borderBottomColor: '#E20074',
    },
    '& .MuiInput-underline:after': {
      //borderBottom:'5px solid #E20074'
      borderBottomColor: '#E20074',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#E20074',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E20074',
      },
    },
    height: '100%'
  },
  input: {
    color: '#fff',
  },
  OutlinedInput: {
    mulitiline: {
      height: 142,
    }
  }
})(TextField);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#555',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ccc',
    },
  },
}))(TableRow);
const author = [];
const collection = []
const typeSetting = [{
    value: 'scriptedVideo',
    label: 'Scripted Video',
  },
  {
    value: 'animation',
    label: 'Animation',
  },
  {
    value: 'screenRecording',
    label: 'Screen Recording',
  },
  {
    value: '360Video',
    label: '360 Video',
  },
  {
    value: 'recordedMeeting',
    label: 'Recorded Meeting',
  },
  {
    value: 'VideoBlog',
    label: 'Video Blog',
  },
  {
    value: 'commercial',
    label: 'Commercial',
  },
  {
    value: 'UGC',
    label: 'UGC',
  },
  {
    value: 'OEM',
    label: 'OEM',
  },
  {
    value: 'agency',
    label: 'Agency',
  },
  {
    value: 'podcast',
    label: 'Podcast',
  },
  {
    value: 'other',
    label: 'Other',
  },
]
const compressionSetting = [{
    value: 'standard',
    label: 'Standard Compression',
  },
  {
    value: 'smallest',
    label: 'Smallest File',
  },
  {
    value: 'large',
    label: 'Large File',
  },
]
let audiences = ['Consumer_Retail_Sales',
  'Business_Sales',
  'Customer_Care_Sprint',
  'Network',
  'IT',
  'HR',
  'Finance',
  'Other_Sprint',
  'Other_Non-Sprint',
]
const studioSetting = [{
    value: 'SprintUniversity',
    label: 'Sprint University',
  },
  {
    value: 'SalesExcellence',
    label: 'Sales Excellence',
  },
  {
    value: 'OmniChannel',
    label: 'Omni Channel',
  },
  {
    value: 'YellowFan',
    label: 'Yellow Fan',
  },
  {
    value: 'VendorSupplied',
    label: 'Vendor Supplied',
  },
  {
    value: 'UGC',
    label: 'UGC',
  },
  {
    value: 'OtherSprint',
    label: 'Other - Sprint',
  },
  {
    value: 'Other',
    label: 'Other',
  },

]
const aspectRatioSetting = [{
    value: '16x9',
    label: '16x9',
  },
  {
    value: '4x3',
    label: '4x3',
  },
]
const subtitleType = [{
  value: 'Subtitle',
  label: 'Subtitle',
}, ]
const languageType = [{
    value: 'English',
    label: 'English',
  },
  {
    value: 'Spanish',
    label: 'Spanish',
  },
]
let numUpload = 0,
  video
const today = new Date().toISOString();

function editAudio(props) {
  // need to update "activity = {video.activityDate} or what ever once available.
  const [Videos, setVideos] = useState([]);
  const { classes } = props;
  let myEmail;
  let data, videoID;
  const vidObj = {};
  const [audienceList, setAudienceList] = useState(audiences);
  const [finishLoading, setFinish] = useState(false);
  const [user, setUser] = useState();
  const [videoURL, setVideo] = useState(null);
  const [thumbURL, setThumb] = useState(null);
  const [captionURL, setCaption] = useState(null);
  const [captionFile, setCaptionFile] = useState();
  const [captionAdded, setCaptionAdded] = useState(true);
  const [hasCaption, setHasCaption] = useState(false);
  const [publishDateVisible, setPublishDateVisible] = useState(false);
  const [archiveDateVisible, setArchiveDateVisible] = useState(false);
  const [captionArray, setCaptionArray] = useState([])
  const [subKey, setSubkey] = useState(utils.generateID(4))
  const [enableCancel, setCancel] = useState(true);
  const [openSnack, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openExpirationError, setOpenExpirationError] = React.useState(false);
   const [subCollection, setSubCollection] = React.useState([]);
   const [artworkcompleted, setArtworkCompleted] = React.useState(0);
  const [uploadFinish, setUpload] = React.useState(true);
  const [showForm, setShowForm] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [instuction, setInstructionText] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("Add Audio");
  const [completed, setCompleted] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [postercompleted, setPosterCompleted] = React.useState(0);
  const [subcompleted, setSubCompleted] = React.useState(0);
  const [scriptcompleted, setScriptCompleted] = React.useState(0);
  const [init, setInit] = React.useState(true);
  const [showStreamSetting, setShowStreamSetting] = React.useState(false);
  const [streamSetting, setStreamSetting] = React.useState(false);
  const [compression, setCompression] = React.useState();
  const [schedule, setSchedule] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(true);
  const [compressType, setCompressType] = React.useState('');
  let [isBlocking, setIsBlocking] = useState(false);
   let subFile 
  const [values, setValues] = React.useState({
    id: '',
    compression: 'standard',
    aspectRation: '16x9',
    PrimaryAuthor: '',
    SecondaryAuthor: 'none',
    writer: 'none',
    creative: 'none',
    businessPartner: '',
    title: '',
    videoType: 'scriptedVideo',
    description: "",
    keywords: '',
    audience: 'Public',
    audienceList: [],
    privacy: '',
    includeInsearch: false,
    captionType: 'Subtitle',
    captionLanguage: 'English',
    scriptLanguage: 'English',
    studio: 'SprintUniversity',
    allowDownload: false,
    includeInClipsSearch: true,
    includeInfederatedSearch: false,
    publishScheduleDate: new Date(),
    publishType: 'Immediate',
    expirationDate: "",
    expiration: 'false',
    audioState: 'ACTIVE',
    scriptURL: "",
    sourceURL:'',
    groupEmployee: true,
    groupIndirectDealer: false,
    groupServicePartners: false,
    groupBusinessIndirect: false,
    groupTelesales: false,
     collectionID: '',
    subcollectionID: '',
    allowTrainerDownload: false,
    lmsSearch:false,
    assignableLearningObj:false,
    catalog: "Restricted",

  });
  const [newValues, setNewValues] = React.useState({});

  var tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  const past = {
    before: tomorrow,
  }

  useEffect(() => {
    // console.log("useEffect")
    // console.log(captionArray.length)
    const userInfo = Cache.getItem('userInfo')
    const myID = userInfo.id

    setUser(userInfo)
    if (init) {
      setShowForm(false)
      setUpload(false)
      numUpload = 0;
      const thisquery = queryString.parse(props.location.search)
      videoID = thisquery.v
      console.log(videoID)
      if (videoID == null) {
        const id = utils.generateID(16) //generate audio ID
        // setValues({ ...values, id: id });

        setValues({ ...values, id: id, primaryAuthor: myID });

      }
      else {
        setEditMode(true)
        setUpload(true)
        setPageTitle('Edit Audio');
        setInstructionText('')
        utils.searchAudioByID(videoID).then(value => {
         // console.log('***')
         console.log(value)
          video = JSON.parse(value.data.searchForAudio)
          console.log(video)
          if (Array.isArray(video.captions)) {
            video.captions.forEach(function(ele, index) {
              video.captions[index].id = index
            })
            setCaptionArray(video.captions)
          }


          video.groupsCanAccess.forEach(value => {
            video.groupEmployee = true
            console.log(value)
            if (value == "Indirect_Dealer") {
              video.groupIndirectDealer = true;
              audienceList.push('Indirect_Dealer')
            }
            if (value == "Service_Partners") {
              video.groupServicePartners = true;
              audienceList.push('Service_Partners')
            }
            if (value == "Business_Indirect") {
              video.groupBusinessIndirect = true;
              audienceList.push('Business_Indirect_Sales')
            }
            if (value == "Telesales") {
              video.groupTelesales = true;
              audienceList.push('Consumer_Telesales')
            }
          })

          if (value.captionLanguage == null) video.captionLanguage = 'English'
          if (value.captionType == null) video.captionType = 'Subtitle'
          while(utils.testDecode( video.description)){
            try {
              video.description =decodeURIComponent( video.description);
              } 
              catch (ex) {
                
                video.description = video.description
              }
       }

          video.publishScheduleDate = video.publishDate;
          video.audienceList.filter(group => group != "IT")
           if (video.collection != null) {
            video.collectionID = video.collection.collection_id;
            video.subcollectionID = video.collection.sub_collection_id
            console.log(video.collection)
            console.log(collection)
            let tmp = collection
            console.log(tmp.filter(e => e.value == video.collection.collection_id).subCollection)
            //setSubCollection(tmp.filter(e => e.value == video.collection.collection_id).subCollection)
          }
          if(video.hasOwnProperty('lms')){
            if(_.isEmpty(video.lms)){
              video.lmsSearch = false;
              video.assignableLearningObj  = false;
            }
            else{
              video.lmsSearch = video.lms.lmsSearch;
              video.assignableLearningObj = video.lms.assignableLearningObj;
            }
          }
          setValues(video)
          setNewValues(video)
          // setShowForm(true)
        }, reason => {});
      }
       utils.getAllCollection().then(value => {
        const getCollection = value.data.searchCollections.items
        // console.log(getCollection)
        let obj
        collection.length = 0;
        let num = 0;

        for (let value of getCollection) {
          obj = {}
          num++;
          obj.label = value.title
          obj.value = value.id

          
          utils.searchCollection(value.id).then(v => {
            let subCollectionArray = []
            var tmp = v.data.searchCollections.items[0]
            let subCollection = tmp.subCollection;
            //console.log(subCollection)
            for (var sub of subCollection.items) {
             // console.log(sub)
              subCollectionArray.push(sub)
            }
            // console.log(subCollectionArray)
            //obj.subCollection = [...subCollectionArray]
            collection.find(e => e.value == value.id).subCollection = [...subCollectionArray]
             //console.log(collection)

          });
          collection.push(obj)
          //  let tmp = collection
          //setSubCollection(tmp.filter(e => e.value == values.collection_id).subCollection)

        }

      });
      // console.log(props)
      utils.listChannelAuthors().then(function(result) {
        console.log(result)
        const getAuthor = result.data.SearchForUsers.items
        let obj
        author.length = 0;
        let num = 0;

        let authorList = getAuthor.filter(val => val.id.includes('Clips-User'))
        console.log(authorList)
        for (let value of authorList) {
          obj = {}
          num++;
          obj.label = value.givenName + ' ' + value.familyName
          obj.value = value.id
          author.push(obj)
        }
        author.sort((a, b) => a.label.localeCompare(b.label))
      });
       setFinish(true)
      console.log(values)
      setInit(false)
    }
    else {
      if (videoURL != null || thumbURL != null) countUpload();
    }

    console.log(captionArray.length)
    if (captionArray.length != 0) {
      setHasCaption(true)

    }
    else if (captionArray.length == 0) { setHasCaption(false) }

  }, [videoURL, thumbURL, captionURL, captionArray]);
 
  const handleChange = name => event => {
    let val
    // console.log(name + ' " ' + event.target.value)
    name == 'includeInClipsSearch' || name == 'includeInfederatedSearch' || name == 'groupIndirectDealer' || name == 'groupServicePartners' || name == 'groupBusinessIndirect' || name == 'groupTelesales' || name == 'allowTrainerDownload' || name == 'lmsSearch'|| name == 'assignableLearningObj'? val = event.target.checked : val = event.target.value
    if (name == 'publishScheduleDate' || name == 'expirationDate') {
      val = new Date(event.target.value).toISOString();
    }
    if (name == 'audienceList' || name == 'keywords') {
      val = event.target.value.split(",")
    }
       if (name == 'collectionID') {
      setSubCollection([])
      console.log(collection)
      setSubCollection(collection.find(e => e.value == event.target.value).subCollection)
      console.log(collection.find(e => e.value == event.target.value).subCollection)
    }
    if(name =='assignableLearningObj'&& event.target.checked==true){
      
      setValues({ ...values, ['assignableLearningObj']: true , ['lmsSearch']: true});
       //setNewValues({ ...newValues, ['assignableLearningObj']: true });
    }
    else if(name =='lmsSearch'&& event.target.checked==false){
      if(values.assignableLearningObj) {}
      else setValues({ ...values, [name]: val });
    }
    else setValues({ ...values, [name]: val });
    checkChanges(name, val)

    //special cases to uncheck audience based on group
    if (name == 'groupVendedCare' || name == 'groupConsumerIndirect' || name == 'groupBusinessIndirect' || name == 'groupTelesales') {
      let target

      switch (name) {
        case 'groupIndirectDealer':
          target = 'Indirect_Dealer'
          break;
        case 'groupServicePartners':
          target = 'Service_Partners'
          break;
        case 'groupBusinessIndirect':
          target = 'Business_Indirect_Sales'
          break;
        case 'groupTelesales':
          target = 'Consumer_Telesales'
          break;
        default:
          // code
      }
      console.log(val)
      if (!val) setAudienceList(audienceList.filter(item => item !== target))
      else console.log(audienceList.push(target)) //setAudienceList(audienceList.push(target))


    }
  };

  const handlePublishDayClick = (day) => {
    const now = new Date() + 1;
    console.log(day)
    console.log(now)
    console.log(now < day)
    setValues({ ...values, ['publishScheduleDate']: day });
    checkChanges('publishScheduleDate', day)
  }

  const handleExpireClick = (day, modifier, dayPickerInput) => {

    let now = new Date()
    let expireDate = new Date(day)
    if (now < expireDate) {
      setValues({ ...values, ['expirationDate']: day });
      checkChanges('expirationDate', day)
    }
    else {
      const input = dayPickerInput.getInput();


      setOpenExpirationError(true)
    }
  }

  function handleRadioChange(event) {
    let val = event.target.value
    let newVal
    if (event.target.name == 'publish') {
      setValues({ ...values, publishType: val });


    }
    if (val == 'false') {
      setValues({ ...values, expiration: false })
      setArchiveDateVisible(false)
    }
    else if (val == 'true') {
      setArchiveDateVisible(true)
      setValues({ ...values, expiration: true })
    }

    checkChanges(event.target.name, val)
  }

  function handlePublishRadioChange(event) {
    let val = event.target.value
    if (val == "Schedule") {
      setValues({ ...values,
        publishType: val,
        audioState: "SCHEDULED"
      });
      setSchedule(true)
      setPublishDateVisible(true)
    }
    else {
      setSchedule(false)
      setValues({ ...values, publishType: val });
      setPublishDateVisible(false)
    }
    checkChanges("publishType", val)
  }

  function handlePrivacyChange(event) {
    let val = event.target.value
    setValues({ ...values, audience: val });
    checkChanges("audience", val)
  }

  const setAudience = (val) => {
    setValues({ ...values, audienceList: val });
    checkChanges("audienceList", val)
  }

  const checkChanges = (name, val) => {
    console.log(name)
    console.log(val)
    //console.log(values[name])
    if (val != values[name]) {
      if (editMode) {
        console.log('new value')
        setNewValues({ ...newValues, [name]: val });
      }
    }
  }
    let subtitleFile = null;
   const makeTextFile = (text, thisfile) =>{
              var data = new Blob([text], {type: 'text/vtt'});
              subFile = new File([data], subFile.name , {type: 'text/vtt'})
              // If we are replacing a previously generated file we need to
              // manually revoke the object URL to avoid memory leaks.
              subtitleFile = subFile;
              setCaptionFile(subFile)
              // returns a URL you can use as a href
              console.log(subFile)
              //return file;
      };
  
  const convertSRT=(srt)=>{
   
      let vtt = "WEBVTT\n\n";
      
      srt.split('\n\n').forEach(block => {
      if (!block) return;
      
      const lines = block.split('\n');
      console.log(lines)
      const [id, time] = lines.shift().split(' --> ');
      const text = lines.join('\n');
      
      vtt += `${time}\n${text}\n\n`;
      });
      
      vtt = vtt.replaceAll(",", ".")
      vtt = vtt.replaceAll("undefined", "")
      console.log(vtt)
     
      return vtt;
     
  }

  const handleVideoDrop = (files) => {
    const compress = String(compression)
    const email = String(user.email)
    const extension = files[0].name.split('.')
    
    if(extension[extension.length - 1]=="m4a") extension[extension.length - 1]="mp3"
    console.log(extension[extension.length - 1])
    const videotype = String(streamSetting)
    console.log(files[0])
    console.log(compress, email, videotype)
    if (files.length > 0) {
      const v = "vco/" + values.id + "/sources/audio." + extension[extension.length - 1]
      const source =process.env.REACT_APP_CLOUDFRONT_URL + v
       setValues({ ...values, ['sourceURL']: source });
      if (!editMode) setIsBlocking(true)
      setUploading(true)
      if (!compression) {

        console.log('no compression')
        // const string = `"compress":"false", "email": ${myEmail}`

        Storage.put(v, files[0], {
            metadata: { email },
            contentType: files[0].type,
            progressCallback(progress) {
              setCompleted((progress.loaded / progress.total) * 100);
            },
          })
          .then(result => {
            setUploading(false)
            setVideo(process.env.REACT_APP_CLOUDFRONT_URL + v)
            // setValues({ ...values,  file:  process.env.REACT_APP_CLOUDFRONT_URL+videoURL,});
          })
          .catch(err => console.log(err));
      }
      else {
        const v2 = "vco/" + values.id + "/sources/audio.mp4"
        console.log('compression')

        Storage.put(v2, files[0], {
            metadata: { email },
            contentType: 'video/mp4',
            progressCallback(progress) {
              setCompleted((progress.loaded / progress.total) * 100);
            },

          })
          .then(result => {
            setUploading(false)
            setVideo(process.env.REACT_APP_CLOUDFRONT_URL + v)
            // setValues({ ...values,  file:  process.env.REACT_APP_CLOUDFRONT_URL+videoURL,});
          })
          .catch(err => console.log(err));

      }
    }
    else {
      removeFile('video');
    }
  }

  const handlePosterDrop = (files) => {
    if (files.length > 0) {
      setUploading(true)
      if (!editMode) setIsBlocking(true)
      const extension = files[0].name.split('.')

      const plink = "vco/" + values.id + "/poster." + extension[extension.length - 1]
      let mimetype
      switch (extension[1]) {
        case 'jpg':
          mimetype = 'image/jpeg'
          break;
        case 'png':
          mimetype = 'image/png'
          break;
        case 'gif':
          mimetype = 'image/gif'
          break;
        default:

      }
      console.log(plink)
      Storage.put(plink, files[0], {
          contentType: mimetype,
          progressCallback(progress) {
            setPosterCompleted((progress.loaded / progress.total) * 100);
          },
        })
        .then(result => {
          setUploading(false)
          setThumb(process.env.REACT_APP_CLOUDFRONT_URL + plink)
          if (editMode) utils.updateAudioArt(values.id, 'poster', process.env.REACT_APP_CLOUDFRONT_URL + plink)
          // setValues({ ...values,  posterImage:  process.env.REACT_APP_CLOUDFRONT_URL+thumbURL,});

        })
        .catch(err => console.log(err));
    }
    else {
      removeFile('poster');
    }
  }
  const handleArtworkDrop = (files) => {
    if (files.length > 0) {
      setUploading(true)
      if (!editMode) setIsBlocking(true)
      const extension = files[0].name.split('.')
      const plink = "vco/" + values.id + "/artwork." + extension[extension.length - 1].toLowerCase()
      let mimetype
      switch (extension[1]) {
        case 'jpg':
          mimetype = 'image/jpeg'
          break;
        case 'png':
          mimetype = 'image/png'
          break;
        case 'gif':
          mimetype = 'image/gif'
          break;
        default:

      }
      console.log(plink)
      Storage.put(plink, files[0], {
          contentType: mimetype,
          progressCallback(progress) {
            setArtworkCompleted((progress.loaded / progress.total) * 100);
          },
        })
        .then(result => {
          setUploading(false)
          setArtURL(process.env.REACT_APP_CLOUDFRONT_URL + plink)
          if (editMode) utils.updateAudioArt(values.id, 'art', process.env.REACT_APP_CLOUDFRONT_URL + plink)
          // setValues({ ...values,  posterImage:  process.env.REACT_APP_CLOUDFRONT_URL+thumbURL,});

        })
        .catch(err => console.log(err));
    }
    else {
      removeFile('poster');
    }
  }
  const handleSubtitleDrop = (files) => {

    if (files.length > 0) {
      setUploading(true)
      if (!editMode) setIsBlocking(true)
      let tmp = files[0].name.split('.')
      subFile = files[0];
       const reader = new FileReader()
        reader.onload = function(e) {
          let file = e.target.result
          
         makeTextFile(convertSRT(file))
        }
      if(tmp[tmp.length-1] == 'srt'){
         reader.readAsText(files[0]);
      }
      else{
        setCaptionFile(files[0])
      }
      console.log(files)
      setCaptionFile(files[0])
      setCaptionAdded(false)
    }
    else {
      setSubCompleted(0);
      setCaptionAdded(true)
      removeFile('sub');
    }
  }

  const handleAddMoreSubtitle = () => {
    setSubCompleted(0);
    setSubkey(utils.generateID(4))
    uploadCaptionFile()
    let newSubtitle = { id: captionArray.length, file: captionURL, type: values.captionType, language: values.captionLanguage }
    setCaptionArray(captionArray => [...captionArray, newSubtitle])
    setCaptionAdded(true)
  }

  const uploadCaptionFile = () => {
    let lang = ''
    switch (values.captionLanguage) {
      case 'Spanish':
        lang = '_SP'
        break;

      default:

    }
    const c = "vco/" + values.id + "/subtitle" + lang + ".vtt"
    console.log(captionFile)
    Storage.put(c, captionFile, {
        contentType: 'text/vtt',
        progressCallback(progress) {
          setSubCompleted((progress.loaded / progress.total) * 100);
        },
      })
      .then(result => {
        setCaptionAdded(false)
        setUploading(false)
        setCaption(process.env.REACT_APP_CLOUDFRONT_URL + c)
        setSubCompleted(0);
        setSubkey(utils.generateID(4))
        setCaptionAdded(true)
        let newSubtitle = { id: captionArray.length, file: process.env.REACT_APP_CLOUDFRONT_URL + c, type: values.captionType, language: values.captionLanguage }
        setCaptionArray(captionArray => [...captionArray, newSubtitle])
        //setCaptionArray(captionArray => [...captionArray, {captionArray[captionArray.length-1].captionFile : process.env.REACT_APP_CLOUDFRONT_URL+c]})
        // setCaptionArray(captionArray.map(item => item.id === index ? {...item, someProp : "changed"} : item ))

        //setValues({ ...values,  captionFile: process.env.REACT_APP_CLOUDFRONT_URL+captionURL,})

      })
      .catch(err => console.log(err));

  }

  const deleteCaption = (id) => {
    console.log(id)
    console.log(captionArray)
    let newArray = captionArray.filter(x => x.id !== id)
    console.log(newArray)
    setCaptionArray(newArray)
  }

  const handleScriptDrop = (files) => {
     let lang = 'en'
    switch (values.scriptLanguage) {
      case 'Spanish':
        lang = 'es'
        break;
      default:
    }
    if (files.length > 0) {
      setUploading(true)
      if (!editMode) setIsBlocking(true)

         const c = "vco/" + values.id + "/transcript/"+  lang  + "/transcript.html"
      Storage.put(c, files[0], {
          contentType: 'text/vtt',
          progressCallback(progress) {
            setScriptCompleted((progress.loaded / progress.total) * 100);
          },
        })
        .then(result => {
          setUploading(false)
          setValues({ ...values, scriptURL: process.env.REACT_APP_CLOUDFRONT_URL + c, })

        })
        .catch(err => console.log(err));
    }
    else {
      removeFile('sub');
    }
  }

  const removeFile = (type) => {
    numUpload--;
    console.log('upload: ' + numUpload)
    switch (type) {
      case 'video':
        setVideo(null)
        break;
      case 'poster':
        setThumb(null)
        break;
      case 'sub':
        setCaption(null)
        break;
      default:
        // code block
    }
    if (numUpload <= 2) {
      setUpload(false)
    }
  }

  const countUpload = () => {
    numUpload++;
    console.log('upload: ' + numUpload)
    if (numUpload >= 2) {
      console.log(thumbURL + ' ' + videoURL + '  ' + captionURL)
      if (thumbURL != null && videoURL != null) {
        console.log('complete upload')
        setUpload(true)
      }
      /*
      else{
         setOpenError(true)
      }
      */
    }
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setOpenError(false);
    setOpenExpirationError(false)
  }

  function handleFinishEditing() {
    utils.editAudio(newValues, values.id, captionArray,values.scriptURL,values.scriptLanguage)
    props.history.push('/authorAudio')
    //props.history.push('/authorVideos')
  }

  function handleEditDetail() {
    setShowForm(true)
    setUploading(true)
     if (values.collectionID != undefined) {
      setSubCollection(collection.find(e => e.value == values.collectionID).subCollection)
    }
  }

  function validateForm() {
    if (values.title == '' || values.description == '' || values.keywords == '') return false;
    else return true
  }

  const handleSave = () => {
    setCancel(false)
    console.log(values)
    if (!showForm && uploadFinish) {
      setShowForm(true)
      setIsBlocking(true)
      setInstructionText('Podcast episode audio needs to follow Clips/Mobile Cast standards. Optionally, you can add artwork for each episode, otherwise a placeholder image will be display. Best practice is use lowercase_name.mp3, lowercase_name.jpg.')
    }
    else {
      if (!editMode) {
        if (validateForm()) {
          setIsBlocking(false)
          utils.createAudio(values, videoURL, thumbURL, captionArray, schedule, compression).then(value => {
            setOpen(true); //if save succesfully, then show message
            props.history.push('/authorAudio')
          }, reason => {
            setOpenError(true)
            console.log(reason)
          });
        }
        else {
          setOpenError(true)
        }

      }
      else {
        if (validateForm()) {
          //  console.log(newValues) //edit video form
          //  console.log(values)
          utils.editAudio(newValues, values.id, captionArray,values.scriptURL,values.scriptLanguage,values.lmsSearch)
          props.history.push('/authorAudio')
        }
        else {
          setOpenError(true)
        }
        //console.log(newValues)
      }

    }
  }

  const onConfirm = () => {
    console.log('leaving')
  }

  const handleCancel = () => {
    props.history.push('/authorAudio')
  }

  const handelShowUpload = (event, newValue) => {
    const value = event.target.value === 'true';
    setCompression(value)

    value ? setShowStreamSetting(true) : setShowStreamSetting(false)
    if (!value) setShowUpload(true)
  }

  const handelShowStreamSetting = (event, newValue) => {
    const value = event.target.value === 'true';
    setShowUpload(value)
  }

  const handleStreamSettingChange = (event) => {
    setStreamSetting(event.target.value);
    setShowUpload(true)
  };



  if (finishLoading) {
    return (
      <div className={classes.form} style={{maxWidth: '1024px', margin: '12px',}} >
      <NavigationPrompt when={isBlocking}>
          {({ onConfirm, onCancel }) => (
             <ConfirmNavigationModal
                when={true}
                onCancel={onCancel}
                onConfirm={onConfirm}
                folderID={values.id}
                type={"audio"}
              />
          )}
    </NavigationPrompt>
      <Typography variant="h4" gutterBottom>{pageTitle} </Typography>
      <Typography variant="h5" gutterBottom>{instuction}</Typography>

      {!showForm &&
      
      <Grid container xs={12} sm={12}  >

         <Typography variant="p" gutterBottom >
       Podcast episode audio needs to follow Clips/Mobile Cast standards. Optionally you can add artwork for each episode, otherwise a placeholder image will be display. Best practice is use lowercase_name.mp3, lowercase_name.jpg.
         </Typography>
         <br/>
          <br/>
        
       
               
         <Grid container sm={12} spacing={2} style={{paddingBottom:10+'px'}}  >
         
          {showUpload &&
         <Grid container xs={12} sm={12} spacing={2}  >
           <Grid item xs={12} sm={4}  >
             <Typography variant="h6" gutterBottom>MP3, M4A, or WAV file*</Typography>
                 <Uploader type="audio" func={handleVideoDrop} completed={completed} filesize={5368706371}/>
                 {/*
              <BorderLinearProgress variant="determinate" color="secondary" value={completed}/>
              
              <DropzoneArea
              dropzoneText="Drag audio here."
                  acceptedFiles={['.mp3','.m4a','.wav', '.aiff']}
                  showFileNames={true}
                  filesLimit={1}
                  maxFileSize={5000000000}
                  onChange={handleVideoDrop.bind(this)}
  
                  dropzoneClass={classes.dropzone}>
                </DropzoneArea>*/}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>Poster Image*</Typography>
              <Uploader type="image" func={handlePosterDrop} completed={postercompleted} filesize={1073741274 } />
              {/*
               <BorderLinearProgress variant="determinate" color="secondary" value={postercompleted}/>
                 <DropzoneArea
                  dropzoneText="Drag artwork here."
                    acceptedFiles={['.gif','.png','.jpg']}
                  showFileNames={true}
                  filesLimit={1}
                  maxFileSize={1000000}
                  onChange={handlePosterDrop.bind(this)}
                  dropzoneClass={classes.dropzone}/>
                  */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>Podcast artwork</Typography>
                <Uploader type="artwork" func={handleArtworkDrop} completed={artworkcompleted} filesize={1073741274 } />
            </Grid>
         
         </Grid>
          }
        </Grid>
        

          <Grid item xs={12} sm={12}>
              <Typography variant="h5" gutterBottom>Accessibility</Typography>
                <Typography variant="p" gutterBottom style={{marginBottom:10}}>
                <p>T-Mobile is committed to accessible media with the use of closed captions.</p>
It's up to you to make sure your video and audio files are captioned. Options include T-Mobile's preferred vendor, <a href='https://go.3playmedia.com/custom-tmobile' target='_blank'>3PlayMedia</a>, or creating your own captions using tools like <a href='https://en.wikipedia.org/wiki/Aegisub' target='_blank'>Aegisub</a>, or <a href='https://helpx.adobe.com/premiere-pro/using/working-with-captions.html' target='_blank'>Adobe Premiere</a>. Captions must use the .VTT or .SRT file format. Learn more about using closed captions with Clips media in our <a href='https://tmobileusa.sharepoint.com/sites/clips/SitePages/Accessibilty.aspx' target='_blank'>documentation</a>.
                </Typography>
                <br/>
                <br/>
                {hasCaption &&
                 <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Caption File</StyledTableCell>
                          <StyledTableCell align="right">Type</StyledTableCell>
                          <StyledTableCell align="right">Language</StyledTableCell>
                           <StyledTableCell align="right"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {captionArray.map(row => (
                          <StyledTableRow >
                            <StyledTableCell component="th" scope="row">
                               {row.file.split("/").pop()}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.type}</StyledTableCell>
                            <StyledTableCell align="right">{row.language}</StyledTableCell>
                            <StyledTableCell align="right">
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={()=>deleteCaption(row.id)}
                              >
                                Delete
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }

         <Typography variant="h6" gutterBottom> Caption File</Typography>
         <Grid container xs={12} sm={12} spacing={6} direction="row" justify="flex-start" alignItems="flex-start">
         <Grid item xs={12} sm={6}>
           <Uploader type="subtitle" func={handleSubtitleDrop.bind(this)} completed={subcompleted} filesize={30000000} />
            {/*
             <BorderLinearProgress variant="determinate" color="secondary" value={subcompleted}/>
             <DropzoneArea
              key={subKey}
              dropzoneText="Drag your caption file in here."
                acceptedFiles={['.vtt']}
              showFileNames={true}
              filesLimit={1}
              maxFileSize={30000000}
              onChange={handleSubtitleDrop.bind(this)}

              dropzoneClass={classes.dropzone}/>
              */}
            </Grid>
          <Grid container xs={12} sm={6} >
           <Grid item xs={12} sm={12}>
              <CssTextField
                  id="filled-select-type"
                  select
                  fullWidth
                  label="Type"
                  value={values.captionType}
                  onChange={handleChange('captionType')}
                  SelectProps={{
                    MenuProps: {
                    },
                  }}
                  margin="normal"
                   variant="outlined"
                >
                  {subtitleType.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CssTextField>
            </Grid>
             <br/>
            <Grid item xs={12} sm={12} >
              <CssTextField
                  id="filled-select-type"
                  select
                  fullWidth
                  label="Language"
                 value={values.captionLanguage}
                  onChange={handleChange('captionLanguage')}
                  SelectProps={{
                    MenuProps: {

                    },
                  }}
                  margin="normal"
                   variant="outlined"
                >
                  {languageType.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CssTextField>
                </Grid>
                </Grid>
            </Grid>

              <Button
              variant="contained"
              size="medium"
              color="primary"
              aria-label="add"
              disabled = {captionAdded}
              className={classes.button}
              onClick= {()=>uploadCaptionFile()}
            >
              Add Caption
            </Button>
            <br/>
            <br/>
         </Grid>
         
         <Grid item xs={12} sm={12} direction="row">
           <Typography variant="h5" gutterBottom>Transcript</Typography>
                 <Typography variant="p" gutterBottom>You can upload a transcript file here to keep everything together.</Typography>
           <Grid item xs={12} sm={6} >
              
              <Uploader type="script" func={handleScriptDrop.bind(this)} completed={scriptcompleted} filesize={30000000} />
          </Grid>
          <Grid  xs={12} sm={6} >
                  
                      <Grid item xs={12} sm={12}>
                        <CssTextField
                            id="filled-select-type"
                            select
                            fullWidth
                            label="Language"
                           value={values.scriptLanguage}
                            onChange={handleChange('scriptLanguage')}
                            SelectProps={{
                              MenuProps: {
          
                              },
                            }}
                            margin="normal"
                             variant="outlined"
                          >
                            {languageType.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </CssTextField>
                          </Grid>
                          </Grid>
                   
                  </Grid>
            </Grid>
      }


  {showForm &&
      <Grid container spacing={3}>

        <Grid item xs={12} sm={4}>
         <CssTextField
            id="filled-select-currency"
            select
            required
            fullWidth
            label="Primary Author"

           value={values.primaryAuthor}
            onChange={handleChange('primaryAuthor')}
            SelectProps={{
              MenuProps: {

              },
            }}

            margin="normal"
           variant="outlined"
          >
            {author.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CssTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
           <CssTextField
              id="filled-select-currency"
              select
              fullWidth
              label="Secondary Author"

             value={values.secondaryAuthor}
              onChange={handleChange('secondaryAuthor')}
              SelectProps={{
                MenuProps: {

                },
              }}

              margin="normal"
               variant="outlined"
            >
              {author.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
         <CssTextField
            id="filled-business-partner-email"
            fullWidth
            label="Business Partner Email"
            placeholder="Optional Email"
            value={values.businessPartner}
            onChange={handleChange('businessPartner')}
              margin="normal"
               variant="outlined">
          </CssTextField>
        </Grid>
          <Grid item xs={12} sm={4}>
           <CssTextField
            id="filled-select-writer"
            select
            fullWidth
            label="Writer/Producer"

           value={values.writer}
            onChange={handleChange('writer')}
            SelectProps={{
              MenuProps: {

              },
            }}
            margin="normal"
             variant="outlined"
          >
            {author.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CssTextField>
        </Grid>

        <Grid item xs={12} sm={4}>
           <CssTextField
            id="filled-select-creative"
            select
            fullWidth
            label="Creative"

           value={values.creative}
            onChange={handleChange('creative')}
            SelectProps={{
              MenuProps: {

              },
            }}
            margin="normal"
             variant="outlined"
          >
            {author.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CssTextField>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CssTextField
            required
            id="country"
             fullWidth
            label="Title"
           placeholder="42 characters or less is recommended"
            value={values.title}
            onChange={handleChange('title')}
             margin="normal"
             variant="outlined"/>
        </Grid>

         <Grid item xs={12} sm={6}>
          <Typography variant="p" gutterBottom>Audio Description*</Typography>
          <TextareaAutosize placeholder="Providing a description is helpful for users to find and set expectations for your content. 200-300 characters is recommended"
          className={classes.descBox} aria-label="minimum height" rows={5} onChange={handleChange('description')} value={values.description} />
        </Grid>

         <Grid item xs={12} sm={6}>
          <Typography variant="p" gutterBottom> Tags*</Typography>
          <TextareaAutosize placeholder="Each tags separated by a comma and space. Choose reusable tags that users are like to search by. Tag similar content with the same reusable tags."
          className={classes.descBox} aria-label="minimum height" rows={5} onChange={handleChange('keywords')} value={values.keywords} />
        </Grid>
        
        <Grid item  sm={12}  direction="row" justify="left"  alignItems="center" >
          <Typography variant="h5" gutterBottom>Collection*</Typography>
          Collections are 'Super tags', Clips uses collections to organize and recommend similar content. Choose from an existing collection or request a new collection from the Clips admin team.
          <Grid item xs={4} sm={4} >
         
           <CssTextField
              id="filled-select-currency"
              select
              fullWidth
              label="Collection"

             value={values.collectionID}
              onChange={handleChange('collectionID')}
              SelectProps={{
                MenuProps: {

                },
              }}

              margin="normal"
               variant="outlined"
            >
              {collection.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>
        </Grid>
        
        {(subCollection.length>0||values.subcollectionID!='') &&
         <Grid item xs={4} sm={4}>
        {console.log(values.subcollectionID)}
           <CssTextField
              id="filled-select-currency"
              select
              fullWidth
              label="Subcollection"

             value={values.subcollectionID}
              onChange={handleChange('subcollectionID')}
              SelectProps={{
                MenuProps: {

                },
              }}

              margin="normal"
               variant="outlined"
            >
            
              {subCollection.map(option => (
                <MenuItem key={option.id} value={option.id}>
               
                  {option.title}
                </MenuItem>
              ))}
              
            </CssTextField>
        </Grid>
        }
        </Grid>

          <Grid item sm={12}>
                <Typography variant="h5" gutterBottom>Search</Typography>
                <Typography variant="p" gutterBottom>Private audios do not show in Clips search results, recently added, recommended, or trending audios. Private audios are useful for review purposes. NOTE: Any T-Mobile employee with a direct link to a private audio can play it, and a private audio may still be embedded in sites like Sharepoint, Cornerstone, OnPoint, etc.</Typography>
                 <br/>
                 <FormControl component="fieldset">

                <FormLabel component="legend"></FormLabel>
                <RadioGroup aria-label="position" name="position" value={values.audience} onChange={handlePrivacyChange} row>
                   <FormControlLabel
                    value="Public"
                    control={<Radio color="secondary" />}
                    label="Public"
                    labelPlacement="end"
                  />
                   <FormControlLabel
                    value="Private"
                    control={<Radio color="secondary" />}
                    label="Private"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>


          </Grid>
          
        

      


        <Grid item xs={12} sm={12}  spacing={3} direction="row" justify="left"  alignItems="center">
            <Typography variant="p" gutterBottom>By default, all media is searchable by title, description, and tags in Clips. Deselect "Include in Clips Search" to hide your content from search results.</Typography>
          
           <FormControlLabel
            control={<Checkbox name="clipSearch" checked={values.includeInClipsSearch} onChange={handleChange('includeInClipsSearch')} />}
            label="Include in Clips Search"
          />
          {/*
           <FormControlLabel
            control={<Checkbox name="fedSearch" checked={values.includeInfederatedSearch} onChange={handleChange('includeInfederatedSearch')} />}
            label="Include in Federated Search"
          />
          */}
        </Grid>

        <Grid item xs={12} sm={12}>
           <Typography variant="h5" gutterBottom>Allow Trainer Download</Typography>
            <Typography variant="p" gutterBottom>When this option is checked, T-Mobile trainers can download content for use in classroom setting with poor internet access</Typography>
            <Grid item sm={10}>
           <FormControlLabel
            control={<Checkbox name="clipSearch" checked={values.allowTrainerDownload} onChange={handleChange('allowTrainerDownload')} />}
            label="Allow trainers to download"
          />
          
            </Grid>
        </Grid>

        
          <Grid item xs={12} sm={12}>
           <Typography variant="h5" gutterBottom>Group Access*</Typography>
            <Typography variant="p" gutterBottom> Restrict group access to Clips content. Users that are not in the approved group will not be able to see your media from the Clips portal. Users will still be able to access through external sites such as and LMS course, media.T-Mobile.com link, or Sharepoint if they have access to those platform. T-Mobile employee can see all Clips video by default.</Typography>
             <br/>
             <FormControlLabel
            control={<Checkbox name="clipSearch" checked={values.groupEmployee}  />}
            label="T-Mobile Employee"
          />
           <FormControlLabel
            control={<Checkbox name="fedSearch" checked={values.groupIndirectDealer} onChange={handleChange('groupIndirectDealer')} />}
            label="Indirect Dealer"
          />
           <FormControlLabel
            control={<Checkbox name="fedSearch" checked={values.groupServicePartners} onChange={handleChange('groupServicePartners')} />}
            label="Service Partners"
          />
        </Grid>
        

        <Grid item xs={12} sm={12}>
           <Typography variant="h5" gutterBottom>Magenta U</Typography>
            <Typography variant="p" gutterBottom>Create an assignable learning object in Magenta U for users to enroll and receive credit. <a style={{color:"#E20074"}} href="https://tmobileusa.sharepoint.com/sites/clips/SitePages/Clips%20--%20Magenta%20U%20Integration.aspx" target="_blank">Learn more</a> </Typography>
            <br/>
         <Grid item xs={12} sm={12}>
             <FormControlLabel
            control={<Checkbox name="clipSearch" checked={values.lmsSearch}  onChange={handleChange('lmsSearch')} />}
            label="Include in Magenta U Search"
          />
          </Grid>
       
         <Grid item xs={12} sm={12}>
           <FormControlLabel
            control={<Checkbox name="fedSearch" checked={values.assignableLearningObj} onChange={handleChange('assignableLearningObj')} />}
            label='Create Magenta U Learning Object'  
          />
          {(values.groupIndirectDealer && values.groupServicePartners) && <span style={{color:(values.assignableLearningObj?"#E20074":"#666") }} >Catalog: General <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.54" viewBox="0 0 18 20.54">
  <path id="Path_258" data-name="Path 258" d="M12,8A3,3,0,1,0,9,5a3,3,0,0,0,3,3m0,3.54A13.154,13.154,0,0,0,3,8V19a13.154,13.154,0,0,1,9,3.54A13.154,13.154,0,0,1,21,19V8A13.154,13.154,0,0,0,12,11.54Z" transform="translate(-3 -2)" fill={values.assignableLearningObj?"#E20074":"#666"}/>
</svg></span> }
            {(!values.groupIndirectDealer || !values.groupServicePartners) && <span style={{color:(values.assignableLearningObj?"#E20074":"#666"), }} >Catalog: Restricted  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.54" viewBox="0 0 18 20.54">
  <path id="Path_259" data-name="Path 259" d="M12,14.27,10.64,13A11.242,11.242,0,0,0,5,10.18v6.95a15.008,15.008,0,0,1,7,2.82,15.008,15.008,0,0,1,7-2.82V10.18A11.242,11.242,0,0,0,13.36,13M19,8.15A13.168,13.168,0,0,1,21,8V19a13.154,13.154,0,0,0-9,3.54A13.154,13.154,0,0,0,3,19V8a13.164,13.164,0,0,1,9,3.54,13.222,13.222,0,0,1,7-3.39M12,6a1,1,0,0,0,.71-1.71,1.014,1.014,0,0,0-1.42,0A1.091,1.091,0,0,0,11,5a1.091,1.091,0,0,0,.29.71A1.042,1.042,0,0,0,12,6m2.12,1.12A2.994,2.994,0,1,1,15,5,3,3,0,0,1,14.12,7.12Z" transform="translate(-3 -2)" fill={values.assignableLearningObj?"#E20074":"#666"}/>
</svg></span> }
       
          </Grid>
       
        </Grid>
        

{/*
        <Grid item xs={12} sm={12}>
           <Typography variant="h5" gutterBottom>Audience Recommendations*</Typography>
            <Typography variant="p" gutterBottom>Add audiences to the right field for content recommendations</Typography>
            <Grid item sm={10}>
            {console.log(audienceList)}
            <TransferList audiences={audienceList} selectedAudiences={values.audienceList? values.audienceList.filter(group => group != "Administrators"):values.audienceList} onChange={setAudience}/>
            </Grid>
        </Grid>
*/}
       

          <Grid item xs={12} sm={12}>
              <Typography variant="h5" gutterBottom>Publish and Archive</Typography>
               <Typography variant="p" gutterBottom>Publish your content immediately, or at a schedule date (content posts at 12am UTC). Set an expiration date to archive your content. You will be notified before your content is archived. The date can be updated, and archived content can be restored.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Publish </FormLabel>
            <RadioGroup aria-label="" name="publish" value={values.publishType} onChange={handlePublishRadioChange}>
             {/* <FormControlLabel value="Suspended" control={<Radio />} label="Suspended" />*/}
              <FormControlLabel value="Immediate" control={<Radio />} label="Immediate" />
              <FormControlLabel value="Schedule" control={<Radio />} label="Schedule" />
              {publishDateVisible &&
              <form className={classes.container} noValidate>
              {/*
                  <TextField
                    id="date"
                    label="Scheduled Publish Date"
                    type="date"
                     defaultValue={values.publishScheduleDate.slice(0,10)}
                    onChange={handleChange('publishScheduleDate')}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  */}
                   <DayPickerInput
                      formatDate={formatDate}
                      parseDate={parseDate}
                      classNames={ classes.datePicker }
                      placeholder={`${formatDate(values.publishScheduleDate)}`}
                      onDayChange={handlePublishDayClick}
                        dayPickerProps={{
                          disabledDays: past
                        }}
                    
                    />

                  </form>
              }
                  </RadioGroup>
                  </FormControl>
        </Grid>
         <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Archive</FormLabel>
            <RadioGroup aria-label="" name="expiration" value={values.expiration.toString()}  onChange={handleRadioChange}>
              <FormControlLabel value="false" control={<Radio />} label="No Expiration" />
              <FormControlLabel value="true" control={<Radio />} label={`Schedule ` } />{formatDate(values.expirationDate)!="Invalid date" && formatDate(values.expirationDate)}
              {archiveDateVisible &&
              <form className={classes.container} noValidate>
                  <DayPickerInput
                      formatDate={formatDate}
                      parseDate={parseDate}
                      classNames={ classes.datePicker }
                      selectedDay={formatDate(values.expirationDate)}
                      placeholder={'Click to select expiration day'}
                      onDayChange={handleExpireClick}
                       dayPickerProps={{
                          disabledDays: past
                        }}
                    />
                  {/*<TextField
                    id="date"
                    label="Scheduled Archive Date"
                    type="date"
                    className={classes.textField}
                    defaultValue={values.expirationDate.slice(0,10)}
                     onChange={handleChange('expirationDate')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />*/}
                </form>
              }
            </RadioGroup>
          </FormControl>
        </Grid>
     </Grid>
  }

    {uploadFinish && !editMode &&
       <Grid item xs={12} sm={12} justify="flex-end" >
           <Button
              variant="contained"
              size="medium"
              color="primary"
              aria-label="add"
              disabled={!uploadFinish}
              className={classes.button}
              onClick= {()=>handleSave()}
            >
              Save and Continue
            </Button>

             <Button
              variant="contained"
              size="medium"
              color="primary"
            onClick= {()=>handleCancel()}
              aria-label="add"
              className={classes.margin}
            >
              Cancel
            </Button>


      </Grid>
    }
    <Grid item xs={12} sm={12} justify="flex-end" >
    {!uploading && editMode &&

           <Button
              variant="contained"
              size="medium"
              color="primary"
              aria-label="add"
              className={classes.margin}
              onClick= {()=>handleEditDetail()}
            >

              Edit Audio Detail
            </Button>
    }
    {uploading && editMode &&

           <Button
              variant="contained"
              size="medium"
              color="primary"
              aria-label="add"
              className={classes.margin}
              onClick= {()=>handleSave()}
            >

              Save Audio Detail
            </Button>
    }

    {editMode &&

              <Button
              variant="contained"
              size="medium"
              color="primary"
            onClick= {()=>handleCancel()}
              className={classes.margin}
            >
              Cancel
            </Button>

    }
      </Grid>


       <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack}
            autoHideDuration={10000}
            onClose={handleClose}
          >
         <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="success"
            message={"Your new video have been created. (" + values.id  +")" }
          />
      </Snackbar>
      <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
         <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="warning"
            message={"Something is wrong, make sure all the fields are filled out before save" }
          />
      </Snackbar>
       <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openExpirationError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
         <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="warning"
            message={"Expiration date can't be earlier than today" }
          />
      </Snackbar>
    </div>
    )
  }
  else return null;
}

export default withStyles(styles)(editAudio);
