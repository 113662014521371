import React, { useState, useEffect } from 'react';
import { Cache } from 'aws-amplify';
import '../style/VideoCard.css';
import Wrap from '../functions/Wrap.js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router"; 
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfoModal from '../admin/infoModal.js'
import VideoInfo from './videoInfo.js';
import ShareVideo from './videoShare.js';
import JSZip from "jszip"
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import * as utils from '../functions/util.js';
import SvgIcon from '@material-ui/core/SvgIcon';
import DownloadIcon from '../images/iconJS/Download.js';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />; 
  }
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },

})(props => (
    <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));



const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


function VideoCard(props) {
    //console.log(props)
    const dropClass = useStyles();
    const user = Cache.getItem('userInfo')
    let channel = []
    if (props.channel != null) channel = removeEmpty(props.channel.items)
    channel = Object.entries(channel); //turn obj into array
    //console.log(channel)
   // const video = props; 

    const id = props.id;
    const title = props.title

    const date = new Date(props.activity);
    const ActivityDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    const [anchorEl, setAnchorEl] = React.useState(null); 
    const [open, setOpen] = React.useState(false);
    const thisClass = props.type != 'static' ? 'vcard' : 'vcardStatic'
    const [cAnchorEl, setcAnchorEl] = React.useState(null);
    const [copen, setcOpen] = React.useState(false);
    const [shareOpen, setShareOpen] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [vidID, setVidID] = React.useState(id);
    const [vidTitle, setVidTitle] = React.useState(props.title); 
    const [menuitem, setMenu] = React.useState(props.videoMenu);
    const [downloading, setDownloading] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [video, setVideo] = React.useState(props);
    const [fitThumbURL, setFitThumbnail] = React.useState(props);
    // const [menuitem, setMenu] = React.useState(props.scheduledArchived?props.videoMenu.filter(menu => menu.name != "Archive"):props.videoMenu);
    // if(props.scheduledArchived) setMenu(props.videoMenu.filter(menu => menu.name == "Archive"))

    const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
    let extension=['']
    
    useEffect(() => {
        console.log(props)
        //console.log('use effect nav')

        const userInfo = Cache.getItem('userInfo')
        // console.log(userInfo)
        if (props.hasOwnProperty('thumbnail')){console.log('thumnail'); extension = props.thumbnail.split('.')}
        else if(props.hasOwnProperty('image')) extension = props.image.split('.')
        console.log(extension)
       setFitThumbnail(`https://${server[2]}/fit-in/220x124/public/vco/${id}/poster.${extension[extension.length-1]}`)
       /*
       if(props.id!=null){
            utils.searchVideoByID2(id).then(value => {
               // console.log(value.data.searchForAVideo)
                let vid = {}
                vid = JSON.parse(value.data.searchForAVideo)
                if(vid!='No video found'){
                    if(props.hasOwnProperty('age')) vid.age = props.age
                    setVideo(vid)
                   
                }
               // console.log(vid)
            });
       }
       */
        //console.log(categories)
    }, [props]);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackOpen(false);
      };

    const openInfoDiaglog = (id) => {
        setInfoOpen(true);
        setAnchorEl(null);
        setVidID(props.id)
    }
    const handleEdit = () => {
        console.log(id)
        if(id.length==12||id.length==13){
         props.history.push('/editVideo?v=' + props.id)
        }
        else if(id.length==16) props.history.push('/editAudio?v=' + props.id)
    }
    const handleInfoClose = () => {
        setOpen(false)
        setInfoOpen(false);
    };
    const handleDownloadClose = () => {
        setDownloading(false);
      };
      const handleDownloadToggle = () => {
        setDownloading(!downloading);
      };
    //console.log(server)
    
    
   

    const handleWatch = () => {
        if (props.type != 'static') {
            props.history.push('/Watch?v=' + id)
        }
    }
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleChannelMenuClick = event => {
        setcAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };

    function removeEmpty(obj) {
        return Object.fromEntries(
            Object.entries(obj)
            .filter(([_, v]) => v.channel != null)
            .map(([k, v]) => [k, v.channel === Object(v) ? removeEmpty(v) : v])
        );
    }
    const handleChannelClose = () => {
        setcAnchorEl(null);
        setcOpen(false)
    };

    const handleShareOpen = () => {
        setAnchorEl(null);
        setShareOpen(true)
    };

    const handleDownload = () => {
        console.log()
       
        handleDownloadToggle()
          utils.searchVideoByID2(id).then(value => {
               // console.log(value.data.searchForAVideo)
                let vid = {}
                vid = JSON.parse(value.data.searchForAVideo)
                if(vid!='No video found'){
                    if(props.hasOwnProperty('age')) vid.age = props.age
                    setVideo(vid)
                   
                }
               // console.log(vid)
            });
       
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `clips_${video.id}.zip`;
        var urls = [

            `${props.video}`,
            `${props.image}`,
        ];
        if (video.captions !== null&&video.captions!==undefined) {
            video.captions.map(c =>
                urls.push(c.file)
            )
        }
        if (video.hasOwnProperty('transcripts')) {
            if (video.transcripts !== null&&video.transcripts.length>0) {
    
                video.transcripts.map(c =>
                   c.url!=''?urls.push(c.url):''
                   
                )
               }
        }
         if (video.hasOwnProperty('chapters')) {
            if (video.chapters !== null&&video.chapters.length>0) {
                video.chapters.map(c =>
                   
                    c.file!=''?urls.push(c.file):''
                   // urls.push(c.file||c.src)
                )
                    }
    }
        urls.forEach(function(url) { { console.log(url) }
            var filename = url;
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(url, function(err, data) {
                if (err) {
                    // console.log(err)
                   // handleDownloadClose(false)
                    throw err; // or handle the error
                }
                let flattenName = filename.split('/')
                //console.log(filename)
                zip.file(flattenName[flattenName.length - 1], data, { binary: true });
                count++;

                if (count == urls.length) {
                    console.log('download')
                    handleDownloadClose(false)
                    zip.generateAsync({ type: 'blob' }).then(function(content) {
                        saveAs(content, zipFilename);
                        setAnchorEl(null);
                    });
                }
            });
        });
    };
    const handleSourceDownload = () => {
        console.log(video)
        setAnchorEl(null);
        handleDownloadToggle()
          utils.searchVideoByID2(id).then(value => {
               // console.log(value.data.searchForAVideo)
                let vid = {}
                vid = JSON.parse(value.data.searchForAVideo)
                if(vid!='No video found'){
                    if(props.hasOwnProperty('age')) vid.age = props.age
                    setVideo(vid)
                    
                      var zip = new JSZip();
           var count = 0;
           var zipFilename = `clips_source_${vid.id}.zip`;
           var urls = [
   
               `${vid.sourceVideo}`,
               `${vid.image||vid.thumbnail}`,
           ];
           if(video.hasOwnProperty('captions')){
            if (vid.captions !== null&&vid.captions!=undefined) {
                if(vid.captions.length>0){
                    vid.captions.map(c =>
                        urls.push(c.file)
                    )
                }
            }
            }
           if (vid.hasOwnProperty('transcripts')) {
            if (vid.transcripts !== null&&vid.transcripts.length>0) {
    
             vid.transcripts.map(c =>
                c.url!=''?urls.push(c.url):''
                
             )
            }
         }
         if (vid.hasOwnProperty('chapters')) {
            if (vid.chapters !== null&&vid.chapters.length>0) {
            vid.chapters.map(c =>
               
                c.file!=''?urls.push(c.file||c.src):''
               // urls.push(c.file||c.src)
            )
                }
        }
           console.log(video.transcript)
   
           urls.forEach(function(url) { { console.log(url) }
               var filename = url;
               // loading a file and add it in a zip file
               JSZipUtils.getBinaryContent(url, function(err, data) {
                   if (err) {
                       // console.log(err)
                       setAnchorEl(null);
                       handleDownloadClose(false)
                     
                      // throw err; // or handle the error
                   }
                   let flattenName = filename.split('/')
                   //console.log(filename)
                   let ext = flattenName[flattenName.length - 1].split('.')
                   if (ext[ext.length-1]!='vtt'){
                    zip.file(flattenName[flattenName.length - 1], data, { binary: true });
                   }
                   else{
                     zip.file(flattenName[flattenName.length - 2] +'/'+ flattenName[flattenName.length - 1], data, { binary: true });
                   }
                   count++;
   
                   if (count == urls.length) {
                       console.log('download')
                       zip.generateAsync({ type: 'blob' }).then(function(content) {
                           saveAs(content, zipFilename);
                           setAnchorEl(null);
                           handleDownloadClose(false)
                       });
                   }
   
               });
   
           });
                   
                }
               // console.log(vid)
            });
          
         
   
       };
    const handleASRDownload = () => {
        console.log(video)
        setAnchorEl(null);
        handleDownloadToggle()
          utils.searchVideoByID2(id).then(value => {
               // console.log(value.data.searchForAVideo)
                let vid = {}
             
             
                    
           var zip = new JSZip();
           var count = 0;
           var zipFilename = `clips_ASR_${props.id}.zip`;
           var urls = [
   
                `${props.ASR.captions.file}`,
               `${props.ASR.transcript.file}`,
           ];
          
       
        
   
           urls.forEach(function(url) { { console.log(url) }
               var filename = url;
               // loading a file and add it in a zip file
               JSZipUtils.getBinaryContent(url, function(err, data) {
                   if (err) {
                       // console.log(err)
                       setAnchorEl(null);
                       handleDownloadClose(false)
                     
                      // throw err; // or handle the error
                   }
                   let flattenName = filename.split('/')
                   //console.log(filename)
                   let ext = flattenName[flattenName.length - 1].split('.')
                   if (ext[ext.length-1]!='vtt'){
                    zip.file(flattenName[flattenName.length - 1], data, { binary: true });
                   }
                   else{
                     zip.file(flattenName[flattenName.length - 2] +'/'+ flattenName[flattenName.length - 1], data, { binary: true });
                   }
                   count++;
   
                   if (count == urls.length) {
                       console.log('download')
                       zip.generateAsync({ type: 'blob' }).then(function(content) {
                           saveAs(content, zipFilename);
                           setAnchorEl(null);
                           handleDownloadClose(false)
                       });
                   }
   
               });
   
           });
                   
                
               // console.log(vid)
            });
          
         
   
       };
       /*
    const handleSourceDownload = () => {
        console.log(video)
        handleDownloadToggle()
        setAnchorEl(null);
        let videoURL
        const source = sourceFileExists(video.id)
        if (source[0]) videoURL = source[1]
        else videoURL = video.video

        var zip = new JSZip();
        var count = 0;
        var zipFilename = `clips_source_${video.id}.zip`;
        var urls = [

            `${videoURL}`,
            `${video.image||video.thumbnail}`,
        ];
        if (video.captions !== null&&video.captions!==undefined) {
            video.captions.map(c =>
                urls.push(c.file)
            )
        }
        if (video.hasOwnProperty('transcripts')) {
            video.transcripts.map(c =>
                urls.push(c.url)
            )
        }

        urls.forEach(function(url) { { console.log(url) }
            var filename = url;
            // loading a file and add it in a zip fileff
            JSZipUtils.getBinaryContent(url, function(err, data) {
                if (err) {
                     console.log(data)
                    setSnackOpen(true)
                    //throw err; // or handle the error
                }
                let flattenName = filename.split('/')
                //console.log(filename)
                zip.file(flattenName[flattenName.length - 1], data, { binary: true });
                count++;

                if (count == urls.length) {
                    console.log('download')
                    handleDownloadClose(false)
                    zip.generateAsync({ type: 'blob' }).then(function(content) {
                        saveAs(content, zipFilename);
                       
                    });
                }

            });

        });

    };
*/
    function sourceFileExists(id) {
        let sourceURL = `${process.env.REACT_APP_CLOUDFRONT_URL}vco/${props.id}/sources/video.mp4`
        var http = new XMLHttpRequest();
        http.open('HEAD', sourceURL, false);
        http.send();
        return [http.status != 403, sourceURL];
    }

    const gotoChannel = (id) => {
        if (props.type != 'static') {
            props.history.push('/Channel?id=' + id)
        }
    }
    const openShare = (id) => {
        setShareOpen(true)
    }

    const closeShare = (id) => {
        setShareOpen(false)
    }
    const videoMenu = () => {
        return (

            <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
    
       {menuitem.map(menu =>
      
               //{menu===undefined && 
                    <StyledMenuItem onClick={()=>{
                        menu.func(props.id);
                        setAnchorEl(null);
                    }}>
                     {/*console.log(menu.name)*/}
                       <ListItemText primary={menu.name} />
                       
                    </StyledMenuItem>
             //  }
        )}
          <StyledMenuItem onClick={()=>handleShareOpen()}>
            <ListItemText primary={"Share"} />
        </StyledMenuItem>
         {console.log(user.id)}
         { (((user.role == "Administrator" )||user.id == props.primaryAuthor ||user.id == props.secondaryAuthor) && props.videoMenu.find(el=>el.name=='Edit')==undefined) &&
               <StyledMenuItem onClick={()=>handleEdit()}>
             
          
            <ListItemText primary={"Edit"} />
        </StyledMenuItem>
         }
        {(user.role=="Administrator" || user.role=="Podcast-Author"||user.role=="Channel-Author"||user.role=="Author")&&
          <StyledMenuItem onClick={()=>handleSourceDownload()}>
            <ListItemText primary={"Download Source"} />
        </StyledMenuItem>
        
        
        }
        
         {((user.role=="Administrator" || user.role=="Podcast-Author"||user.role=="Channel-Author"||user.role=="Author")&& props.ASR!=null)&&
          <StyledMenuItem onClick={()=>handleASRDownload()}>
            <ListItemText primary={"Download ASR Files"} />
        </StyledMenuItem>
        
        
        }
       
         {(user.role!="Viewer" && props.allowTrainerDownload)&&
          <StyledMenuItem onClick={()=>handleDownload()}>
            <ListItemText primary={"Trainer Download"} />
        </StyledMenuItem>  
        }
        
          <StyledMenuItem onClick={()=>openInfoDiaglog()}>
            <ListItemText primary={"Properties"} />
        </StyledMenuItem>
    
       
      </StyledMenu>
        )
    }


    const channelMenu = () => {


        if (channel.length > 0) {
            return (

                <StyledMenu
            id="customized-menu"
            anchorEl={cAnchorEl}
            keepMounted
            open={Boolean(cAnchorEl)}
            onClose={handleChannelClose}
          >
          
           {channel.map(menu =>
         
            <StyledMenuItem onClick={()=>{
               gotoChannel(menu[1].channel.id)
                setcAnchorEl(null);
            }}>
            
               <ListItemText primary={menu[1].channel.title} />
            </StyledMenuItem>
           
            )}
           
          </StyledMenu>

            )
        }

    }

    return (
        
  <div class={thisClass} id="49mwdxLb">
      <Backdrop
      className={dropClass.backdrop}
      open={downloading}
     
    >
        <CircularProgress color="inherit" />
        </Backdrop>
  <div class="topHover"></div>
  <div class="vcardThumb" onClick={handleWatch}>
   {(user.role!="Viewer" && props.allowTrainerDownload)&& <div class="videoDownload"><DownloadIcon style={{margin:4}} onClick={()=>handleDownload()}/></div>}
    <LazyLoadImage src={fitThumbURL} alt="video thumbnail" alt={props.title } style={props.imageStyle}/>
    <div class="videoDuration">{props.duration}</div>
  </div>
  <div class="vcardMetadataWrapper">
    <div class="videoProgressBar videoProgressBarNone"><div class="videoProgress videoProgressNone"></div></div>
    <div class="vcardMetadata">
    {props.type!='static' &&
      <div class="vcardMoreButton" onClick={handleClick}>
        <svg style={{width:24+'px',height:24+'px'}} viewBox="0 0 18 24">
        <path d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
    </svg>
      </div>
    }
      <h1 class="videoTitle" title={props.title}>
      {props.title}
      </h1>
      {/*console.log(props.channel)*/}
    {channel[0]!=null &&
    <div>
        {console.log(channel)}
      {channel.length>0 &&
      <div class="channelRow">
         {console.log('test')}
        <div class="videoChannel" onClick={()=>gotoChannel(channel[0][1].channel.id)}>
         {channel[0][1].channel!=null &&  
         <div>
         <span class="channelTitle">{utils.truncate(channel[0][1].channel.title,24)} </span><span>{'  '}</span>
          <span class="channelIcon">
            <svg style={{width:16, height:16, verticalAlign:'top',leftPadding:3 }} viewBox="0 0 24 19">
               <path d="M2.5,4.5H21.5C22.34,4.5 23,5.15 23,6V17.5C23,18.35 22.34,19 21.5,19H2.5C1.65,19 1,18.35 1,17.5V6C1,5.15 1.65,4.5 2.5,4.5M9.71,8.5V15L15.42,11.7L9.71,8.5M17.25,21H6.65C6.35,21 6.15,20.8 6.15,20.5C6.15,20.2 6.35,20 6.65,20H17.35C17.65,20 17.85,20.2 17.85,20.5C17.85,20.8 17.55,21 17.25,21Z" />
            </svg>
          </span>
          </div>
         }
        </div>     
        {channel[0].length>1 &&
        <span class="moreChannels" onClick={handleChannelMenuClick}>
          <svg style={{width:24,height:24}} viewBox="0 0 24 20">
              <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
          </svg>
        </span>
        }
      </div>   
        
      }
      </div>
       }
      <span class="videoViewsAge">
       {!props.noDate  &&<span> {props.views||'0'} Plays • </span>}
         {props.age}</span>
    </div>
    
    <ShareVideo open={shareOpen} close={closeShare} id={id} title={props.title} audience={props.audience}/>
    <VideoInfo open={infoOpen} close={handleInfoClose} id={vidID} router={props.history}/>
    <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error">
          There is an issue with this download, Please contact the Clips Help Center.
        </Alert>
      </Snackbar>
  </div>
  {props.videoMenu!=null &&
   videoMenu()
  }
  
  {props.channel!==undefined &&
   channelMenu()
  }
  
 
  
</div>
    )

}

export default withRouter(VideoCard);
