import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Videoitem from './VideoCard.js';
import './videopage/VideoItem.css';
import Typography from '@material-ui/core/Typography';
import Age from '../functions/Age.js';
import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as utils from '../functions/util.js';
import { withRouter } from "react-router";
import queryString from 'query-string'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ChannelTile from './Subscriptions/ChannelTile.js';

const userInfo = Cache.getItem('userInfo')
//console.log(userInfo)
async function fetchData(searchQuery, paramKey, param) {
  const searchResult = await API.graphql(
    graphqlOperation(searchQuery, { paramKey: param })
  );
  return searchResult;
}

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';

const copyVideoID = (id) => {
  navigator.clipboard.writeText(id)
  if (isChrome || isFirefox) navigator.clipboard.writeText(id)
  else if (isIE) {
    navigator.clipboard.writeText(id)

    window.clipboardData.setData('Text', id);
    document.execCommand('copy');
  }

}


const AntTabs = withStyles({
  root: {

    backgroundColor: '#000',
  },
  indicator: {
    backgroundColor: '#E20074',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    backgroundColor: theme.palette.background.paper,

    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [

    ].join(','),
    '&:hover': {
      color: '#E20074',
      opacity: 1,
    },
    '&$selected': {
      color: '#E20074',

    },
    '&:focus': {
      color: '#E20074',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));

function Videopage(props) {
  // need to update "activity = {video.activityDate} or what ever once available.
  console.log(props)
  let hasVideo 
  let hasAudio
  let hasChannel
  const classes = useStyles();
  const [Videos, setVideos] = useState([]);
  const [Audios, setAudios] = useState([]);
  const [Channels, setChannels] = useState([]);
  const [finishLoading, setFinish] = useState(false);
  const [value, setValue] = React.useState(0);
  const [keyword, setKeyword] = useState('');
   const [searching, setSearching] = useState(false);
   const [videoIndex, setVideoIndex] = useState(0);
   const [audioIndex, setAudioIndex] = useState(1);
   const [channelIndex, setChannelIndex] = useState(2);
  let data, func

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const setIndexFunc=()=>{
    if(!hasVideo&&hasAudio&&!hasChannel){
      setAudioIndex(0);
    }
    else if(!hasVideo&&hasAudio&&hasChannel){
      setAudioIndex(0);
      setChannelIndex(1);
    }
    else if(!hasVideo&&!hasAudio&&hasChannel){
      setChannelIndex(0);
    }
    else if(hasVideo&&!hasAudio&&hasChannel){
      setVideoIndex(0);
      setChannelIndex(1);
    }
    else if(hasVideo&&hasAudio&&hasChannel){
     setVideoIndex(0);
     setAudioIndex(1)
      setChannelIndex(2);
    }

  
      setFinish(true)
  }

  useEffect(() => {
    console.log(props)
    setFinish(false)
    setKeyword(queryString.parse(props.location.search).keyword)
    setVideoIndex(0)
    setAudioIndex(1);
    setChannelIndex(2);
    utils.searchVideo(queryString.parse(props.location.search).keyword).then(function(result) {
      console.log(result)
      let VideosArray = [];
      let playlistItem = result.data.searchVideoByKeyword.items
      hasVideo = playlistItem.length>0?true:false;
      for (var value of playlistItem) {
        var obj = {};
        obj.id = value.id;
        obj.audience = value.allowPublicAccess
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.poster_image_loc = value.thumbnail
        obj.views = value.videoViews
        obj.channels = value.channels
        obj.duration = utils.formatDuration(value.videoDuration)
        obj.author = value.primaryAuthor
        obj.captions = value.captions
        obj.video = value.videoURL
        obj.primaryAuthor = value.primaryAuthor
        obj.secondaryAuthor = value.secondaryAuthor
        obj.allowTrainerDownload = value.allowTrainerDownload
        obj.ASR = value.ASR
        VideosArray.push(obj)
      }
      setVideos(VideosArray)
     //if(Videos.length==0) setValue(1)
         utils.searchAudioByKeywords(queryString.parse(props.location.search).keyword.toString()).then(function(result) {
          console.log('audio result')
          console.log(result)
          let VideosArray = [];
          let playlistItem = result.data.searchAudioByKeyword.items
          hasAudio = playlistItem.length>0?true:false;
          for (var value of playlistItem) {
            var obj = {};
            obj.id = value.id;
            obj.audience = value.allowPublicAccess
            obj.video_title = value.title;
            obj.video_desc = value.description;
            obj.createdAt = value.createdAt;
            obj.poster_image_loc = value.thumbnail
            obj.views = value.videoViews
            obj.channels = value.channels
            obj.duration = utils.formatDuration(value.audioDuration)
            obj.author = value.primaryAuthor
            obj.captions = value.captions
            obj.video = value.sourceURL
            obj.allowTrainerDownload = value.allowTrainerDownload
            obj.ASR = value.ASR
            VideosArray.push(obj)
          }
          setAudios(VideosArray)
          //if(Videos.length==0&&Audios.length==0)setValue(2)
           utils.searchChannelByKeyword(queryString.parse(props.location.search).keyword).then(function(result) {
            console.log(result.data.searchChannels.items)
            hasChannel =result.data.searchChannels.items.length>0?true:false;
            let channelArray = [];
            setChannels(result.data.searchChannels.items)
            setIndexFunc();
           
            utils.createHistory(queryString.parse(props.location.search).keyword)
         
          });
        });
    });
    
   
   


   
   // console.log(Videos)
    //console.log(Audios)
   

  }, [props.location.search]);

  const editVideo = (id) => {
    props.history.push('/editVideo?v=' + id)
  }

  if (finishLoading) {
    
    return (
      <div className={classes.root}>
       
        <Typography variant="h5" gutterBottom>Search results for "{keyword}"</Typography>
      <AppBar position="static">
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
     
      {Videos.length!=0 && <LinkTab label={Videos.length +" Videos"}  {...a11yProps(0)} disabled={Videos.length==0}/>}  
      {Audios.length!=0 && <LinkTab label={Audios.length +" Audios"}  {...a11yProps(1)} disabled={Audios.length==0}/>}
      {Channels.length!=0 &&  <LinkTab label={Channels.length + " Channels"}  {...a11yProps(2)} disabled={Channels.length==0} />}
     
    
        </AntTabs>
      </AppBar>

      <TabPanel value={value} index={videoIndex}>
         <div class="thumbnailContentBlock" style={{margin: '12px',}}>
      {/*Videos.length==0 &&
      <div>
        <Typography variant="h5" gutterBottom>Your search - <b>{keyword}</b> did not match any videos in Clips.</Typography>
        <p>Suggestions:</p>
        <ul>
          <li>Make sure all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more gedneral keywords.</li>
        </ul>
        </div>
      */}

          <div class='videoThumbRow' style={{marginLeft: '0'}}>
            {Videos.map(video =>
            <div>
              <Videoitem
                audience={video.audience}
                activity = {video.createdAt}
                image = {video.poster_image_loc}
                title = {video.video_title}
                views = {video.views}
                age = {Age(video.createdAt)}
                duration = {video.duration}
                //channel = {video.channel.channel_title}
                id = {video.id}
                channel ={video.channels}
                captions={video.captions}
                video = {video.video}
                secondaryAuthor={video.secondaryAuthor}
                primaryAuthor = {video.primaryAuthor}
                allowTrainerDownload={video.allowTrainerDownload}
                 ASR = {video.ASR}
                videoMenu={[
                      {name:'Copy Video ID', func:copyVideoID},
                     
                    
                      ]}
              />
           
              </div>
                )}
          </div>
        </div>
      </TabPanel>
      
       <TabPanel value={value} index={audioIndex}>
         <div class="thumbnailContentBlock" style={{margin: '12px',}}>
      

          <div class='videoThumbRow' style={{marginLeft: '0'}}>
            {Audios.map(video =>
            <div>
              {console.log(video)}
              <Videoitem
                audience={video.audience}
                activity = {video.createdAt}
                image = {video.poster_image_loc}
                title = {video.video_title}
                views = {video.views}
                age = {Age(video.createdAt)}
                duration = {video.audioDuration}
                //channel = {video.channel.channel_title}
                id = {video.id}
                channel ={video.channels}
                captions={video.captions}
                video = {video.video}
                ASR = {video.ASR}
                allowTrainerDownload={video.allowTrainerDownload}
                videoMenu={[
                      {name:'Copy Audio ID', func:copyVideoID},
                     
                    
                      ]}
              />
           
              </div>
                )}
          </div>
        </div>
      </TabPanel>
      

      <TabPanel value={value} index={channelIndex}>
      <div>
         {Channels.map(channel =>
         <div>
         {console.log(channel)}
          <ChannelTile
            id = {channel.id}
            icon = {channel.iconImage}
            title = {channel.title}
            description = {channel.description}
            subscribed= {channel.subscribed}
            subscribers={channel.subscribers}
            updated = {utils.formatDate(channel.createdAt)}
            playlist = {channel.playlists!=null? channel.playlists.items.length: 0}
            videos={channel.videos!=null? channel.videos.items.length: 0}
            showButton ={false}
            showInfo={true}
          />
          </div>
          )}
          </div>
      </TabPanel>

    </div>

    )
  }
  else return null;
}

export default withRouter(Videopage);
