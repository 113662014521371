import Amplify, { graphqlOperation, API, Storage, Cache, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports.js';
Amplify.configure(awsconfig);

const userInfo = Cache.getItem('userInfo')
Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: "clips-sprint-develop" //REQUIRED -  Amazon S3 bucket.
    }
  }
});

const randomNumber = (length) => {
  return Math.floor(Math.random() * length)
}

export const cleanUpTextField = (str) => {
  return str.replace(/(\r\n|\n|\r)/gm, " ");
}

export const truncate = (str, n) => {
  console.log(str)
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};

export const textToAscii = (text) => {
  let asciiValues = '';
  for (let i = 0; i < text.length; i++) {
    asciiValues+=(text.charCodeAt(i) + ' ');
  }
  return asciiValues;
};

export const generateID = (length) => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let text = "";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(randomNumber(possible.length));
  }
  return text;
}

export const testDecode = (str) => {
  // Check if the string contains any characters that need to be decoded
  for (var i = 0; i < str.length; i++) {
    if (str.charAt(i) === '%') {
      return true;
    }
  }
  return false;

}

export const validateEmail = (email) => {

  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)

}



export const getImageURL = (props) => {
  console.log(props)
}
/*
    Storage.get(props.key)
    .then(result => {
        console.log(result)
        return result
    })
    .catch(err => console.log(err));
*/



export const likeVideo = async (prop) => {

  let tag = []
  for (let value of prop.keywords) {
    tag.push(`${value.replace(/[^\w\s]/gi, '')}`)
  }

  const likeVid = `mutation Like {
         likeVideo(input:{
         videoId:"${ prop.id }"
         title: "${  prop.title }"
         description: "${  prop.video_desc }"
         videoDuration:"${unformatDuration(prop.duration)}"
         thumbnail: "${ prop.key }"})
      {
        videoId
        like
      }
    }`

  const op = API.graphql(
    graphqlOperation(likeVid)
  );

  var data = {
    "Event": {
      "itemId": `${prop.id}`,
      "keywords": tag
    },
    "SessionId": `${generateID(10)}`,
    "EventType": "LIKE",
    "UserId": `${userInfo.id}`
  }

  data = JSON.stringify(data)

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      console.log(this.responseText);
    }
    console.log(this.responseText)
  });

  Auth.currentAuthenticatedUser({}).then(user => {
    let accessToken = user.signInUserSession.idToken
    let jwt = accessToken.getJwtToken()
    //  console.log(`myJwt:${jwt}`)

    //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
    xhr.open("POST", `${process.env.REACT_APP_API_HOST}/events`, true);
    xhr.setRequestHeader("authorization", `Bearer ${jwt}`)
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(data);
  });
  /*
  getAuthorizeToken().then(function(response) {
      const token = JSON.parse(response);

      xhr.open("POST", `${process.env.REACT_APP_API_HOST}/events`, true);
      xhr.setRequestHeader("authorization", `Bearer ${token.access_token}`);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(data);
    })
    .catch(function(err) {
      console.error('Augh, there was an error!', err.statusText);
    });
*/
}

export const unlikeVideo = (id) => {

  const likeVid = `mutation Unlike {
        unLikeVideo(input:{
        videoId:"` + id + `"
      })
      {
        videoId
        unlike
      }
    }`
  const op = API.graphql(
    graphqlOperation(likeVid)
  );
}

export const favoriteVideo = (prop) => {

  let tag = []
  for (let value of prop.keywords) {
    tag.push(`${value.replace(/[^\w\s]/gi, '')}`)
  }
  //console.log(unformatDuration(prop.duration))
  const likeVid = `mutation Favourite {
          favoriteAVideo(input:{
          videoId:"${ prop.id }"
          title: "${ prop.title }"
          description: "${prop.video_desc }"
          thumbnail: "${ prop.key}"
          videoDuration:"${unformatDuration(prop.duration)}"
      })
      {
        videoId
        favorite
      }
    }`
  const op = API.graphql(
    graphqlOperation(likeVid)
  );

  var data = JSON.stringify({
    "Event": {
      "itemId": `${prop.id}`,
      "keywords": tag
    },
    "SessionId": `${generateID(10)}`,
    "EventType": "FAVORITE",
    "UserId": `${userInfo.id}`
  })


  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      console.log(this.responseText);
    }
    console.log(this.responseText)
  });
  Auth.currentAuthenticatedUser({}).then(user => {
    let accessToken = user.signInUserSession.idToken
    let jwt = accessToken.getJwtToken()
    //  console.log(`myJwt:${jwt}`)

    //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
    xhr.open("POST", `${process.env.REACT_APP_API_HOST}/events`, true);
    xhr.setRequestHeader("authorization", `Bearer ${jwt}`)
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(data);
  });
  /*
  getAuthorizeToken().then(function(response) {
      const token = JSON.parse(response);

      xhr.open("POST", `${process.env.REACT_APP_API_HOST}/events`, true);
      xhr.setRequestHeader("authorization", `Bearer ${token.access_token}`);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(data);
    })
    .catch(function(err) {
      console.error('Augh, there was an error!', err.statusText);
    });
*/
}

export const unfavoriteVideo = (id) => {

  const likeVid = `mutation UnFavorite {
        unFavoriteAVideo(input:{
        videoId:"` + id + `"
      })
      {
       videoId
       unfavorite
      }
    }`
  const op = API.graphql(
    graphqlOperation(likeVid)
  );
}

export const subscribe = async (id, title, desc, thumbnail) => {
  const param = `mutation Subscribe {
          subscribeToChannel(input:{
                subscriptionsChannelId:"` + id + `"
                title:"` + title + `"
                description:"` + desc + `"
                iconImage:"` + thumbnail + `"
              })
              {
                subscribed
              }
            }`
  console.log(param)
  const op = API.graphql(
    graphqlOperation(param)
  ).then(
    result => window.location.reload(false),

  );

  return (op)
}

export const unsubscribe = async (id) => {
  const param = `mutation unsubcribe {
  unSubscribeToChannel(input: {
    subscriptionsChannelId: "${id}",
    userId: "${userInfo.id}"
  }){
    unsubscribed
  }
}      `
  const op = API.graphql(
    graphqlOperation(param)
  ).then(
    result => window.location.reload(false),

  );;

  return (op)
}

export const unsubscribeToPodcast = async (id) => {
  //console.log(id)
  const param = `mutation UnsubscribeToPodcastShow {
  unSubscribeToPodcastShow(input:{
    podcastId:"${id}"
  })
  {
    unsubscribed
  }}`
  console.log(param)


  const op = API.graphql(
    graphqlOperation(param)
  ).then(
    // result => window.location.reload(false),

  );

  return (op)
}

export const subscribeToPodcast = async (obj) => {
  const param = `mutation SubscribeToPodcastShow {

  subscribeToPodcastShow(input:{
    podcastId:"${obj.id}"
    title:"${obj.title}"
    description:"${obj.description}"
    art: "${obj.art}"
    bannerArt: "${obj.bannerArt}"
  })
  {
    subscribed
  }
}      `
  console.log(param)
  const op = API.graphql(
    graphqlOperation(param)
  ).then(
    // result => window.location.reload(false),

  );;

  return (op)
}

export const searchVideo = (keyword) => {
  const param = `query SearchVideosByKeyword{
               searchVideoByKeyword(keyword:"${keyword}")
              {
                items{
                   id

                   audience
                   allowPublicAccess
                   allowTrainerDownload
                   title
                   description
                   thumbnail
                   ASR {
                    transcript {
                      file
                      language
                      display
                      createdAt
                    }
                    captions {
                      file
                      language
                      display
                      createdAt
                    }
                  }
                   groupsCanAccess
                   videoViews
                   createdAt
                   videoDuration
                    primaryAuthor
                    secondaryAuthor
                    captions{
                      file
                    }
                    videoURL
                   channels{
                    items{
                      channel{
                        id
                        title
                      }
                    }
                  }

                }
              }
            }`

  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const createHistory = (keyword) => {
  console.log(keyword)
  const param = `mutation createHistory {
    createHistory(input: {
        id:"${generateID(32) }"
        searchKeywords:"${keyword}"
    }){
     id
     searchKeywords
     historyUserId
     createdAt
    }
}`

  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getSearchHistory = async (keyword) => {
  const param = `query SearchHistory{    searchHistorys(filter: {
      searchKeywords: { matchPhrasePrefix:"${keyword}"}
      }
       sort: {
          field: createdAt
          direction: desc
      }
      limit: 200
      ) {
          items {
            title
            id
            historyUserId
              createdAt
              searchKeywords
          }
      }
  }`

  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const deleteHistory = async (id) => {
  const param = `mutation dleteHistory{
      deleteHistory(input:{
        id:"${id}"
      }
      ){
          id
      }
      }`

  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const listAuthors = () => {
  const param = `query listUser{
  listUsers(
    filter:{
     role:{
      eq:"Author"
    }

    }
  )
    {
      items{
        id
        familyName
        givenName
      }
    }
}`
}

export const listPodcastAuthors = () => {
  const param = `query SearchForUsers {
  SearchForUsers(role:"Administrator,Author,Podcast-Author")
  {
    items{
      id
      role
      familyName
      givenName
    }
  }
}`


  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const listChannelAuthors = () => {
  const param = `query SearchForUsers {
  SearchForUsers(role:"Administrator,Channel-Author,Podcast-Author")
  {
    items{
      id
      role
      familyName
      givenName
    }
  }
}`


  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const listUser = () => {
  const param = `query SearchUsers{ searchUsers(
  filter:{
    role:{
      matchPhrase:"Author"
    }
  }
  limit:120)
  {
    items{
      id
      familyName
      givenName
      role
      profilePhotoURL
    }
  }
}`


  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const listAdmin = () => {
  const param = `query SearchUsers{ searchUsers(
  filter:{
    role:{
      matchPhrase:"Administrator"
    }
  }
  limit:120)
  {
    items{
      id
      familyName
      givenName
      role
      profilePhotoURL
    }
  }
}`


  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const listPodcaster = () => {
  const param = `query SearchUsers{ searchUsers(
  filter:{
    role:{
      matchPhrase:"Podcaster"
    }
  }
  limit:120)
  {
    items{
      id
      familyName
      givenName
      role
      profilePhotoURL
    }
  }
}`


  const op = API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const createVideo = async (v, video, poster, captionArray, schedule, compression, subID) => {
  console.log(v)

  let tag = ''
  let group = '"Administrators",'
  let businessPartnerParam = ''


  let expriationDateParam = ""
  for (let value of v.keywords) {
    tag += `"` + value + `",`
  }
  if (v.allowTrainerDownload) tag += `"trainerdownload, trainer_download"`
  else tag = tag.replace("trainerdownload, trainer_download",'')
  for (let value of v.audienceList) {
    group += `"` + value + `",`
  }

  if (v.businessPartner != '') {
    businessPartnerParam = `businessPartner:"` + v.businessPartner + `",`
  }

  let videoState = v.videoState;
  if (compression) videoState = "TRANSCODING"
  if (compression && schedule) videoState = "TRANSCODING-SCHEDULED"
  let captionParam = ''
  if (captionArray.length > 0) {
    let captionContent = ""
    captionArray.forEach(element =>
      captionContent += `{
                      file: "${element.file}"
                      language: "${element.language}",
                      type: "${element.type}"
                 }`
    )
    captionParam = `captions: [${captionContent}]`
  }
  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  if (v.groupIndirectDealer) groupCanAccess += `"Indirect_Dealer",`
  if (v.groupServicePartners) groupCanAccess += `"Service_Partners",`
  if (v.groupVendedCare) groupCanAccess += `"Vended_care",`
  if (v.groupConsumerIndirect) groupCanAccess += `"Consumer_Indirect",`
  if (v.groupBusinessIndirect) groupCanAccess += `"Business_Indirect",`
  if (v.groupTelesales) groupCanAccess += `"Telesales",`

  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  let scriptParam = ""
  console.log(v.scriptURL)
  if (v.scriptURL != "") scriptParam = 'transcripts:[{url:"' + v.scriptURL + '", srcLang:"English",type:"Transcript"}]'

  if (v.expirationDate != "") {
    expriationDateParam = `expirationDate:"` + new Date(v.expirationDate).toISOString() + `"`
  }
  let lmsParam = `lms:{`
  lmsParam += `lmsSearch:` + v.lmsSearch || false
  lmsParam += `, assignableLearningObj:` + v.assignableLearningObj || false;
  lmsParam += `, catalog:"` + (v.groupIndirectDealer && v.groupServicePartners ? "General" : "Restricted")
  lmsParam += `"}`

  console.log(tag)
  const param = `mutation CreateVideo {
              createVideo(input:{
                id:"` + v.id + `"
                title: "` + cleanUpTextField(v.title) + `"
                description: "` + encodeURIComponent(v.description.replace(/\"/g, "&#8220")) + `",
                thumbnail: "${poster}",
                primaryAuthor: "` + v.primaryAuthor + `"
                secondaryAuthor: "` + v.secondaryAuthor + `"
                videoURL: "${video}",
                aspectRation:"` + v.aspectRation + `",
                compression:"` + v.compression + `",
                writer:"` + v.writer + `",
                creative:"` + v.creative + `",
                videoType:"` + v.videoType + `",
                includeInClipsSearch: ` + v.includeInClipsSearch + `
                allowDownload: ` + v.allowDownload + `
                audienceList:[ ` + group + `]
                keywords: [ ` + cleanUpTextField(tag) + ` ]
                videoState:"${videoState}"
                publishDate:"` + new Date(v.publishScheduleDate).toISOString() + `"
                publishType:"` + v.publishType + `"
                allowTrainerDownload:` + v.allowTrainerDownload + `
                expiration:` + v.expiration + ` studio:"` + v.studio + `"
                audience:"` + v.audience + `"
                includeInfederatedSearch:${v.includeInfederatedSearch}
                chapters: [{file: "${v.chapterURL}",language: "English",sourceName: "chapter.vtt" }]
                ` + businessPartnerParam + `
                ` + captionParam + `
                ` + expriationDateParam + `
                ` + groupParam + `
                ` + scriptParam + `
                ` + lmsParam + `
              })
              {
                id
                title
                description
                primaryAuthor
                owner
                createdAt
              }
            }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  if (subID != null || subID !== undefined) addVideoToCollection(v.id, subID, groupParam)
  //else { addVideoToCollection(id, '001') }
}

export const createAudio = async (v, audio, poster, captionArray, schedule, compression) => {
  console.log(v)
  let tag = ''
  let group = '"Administrators",'
  let businessPartnerParam = ''


  let expriationDateParam = ""
  for (let value of v.keywords) {
    tag += `"` + value + `",`
  }

  for (let value of v.audienceList) {
    group += `"` + value + `",`
  }

  if (v.businessPartner != '') {
    businessPartnerParam = `businessPartner:"` + v.businessPartner + `",`
  }

  let videoState = v.videoState;
  if (compression) videoState = "TRANSCODING"
  if (compression && schedule) videoState = "TRANSCODING-SCHEDULED"
  let captionParam = ''
  if (captionArray.length > 0) {
    let captionContent = ""
    captionArray.forEach(element =>
      captionContent += `{
                      file: "${element.file}"
                      language: "${element.language}",
                      type: "${element.type}"
                 }`
    )
    captionParam = `captions: [${captionContent}]`
  }
  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  if (v.groupIndirectDealer) groupCanAccess += `"Indirect_Dealer",`
  if (v.groupServicePartners) groupCanAccess += `"Service_Partners",`
  if (v.groupVendedCare) groupCanAccess += `"Vended_care",`
  if (v.groupConsumerIndirect) groupCanAccess += `"Consumer_Indirect",`
  if (v.groupBusinessIndirect) groupCanAccess += `"Business_Indirect",`
  if (v.groupTelesales) groupCanAccess += `"Telesales",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  let scriptParam = ""
  console.log(v.scriptURL)
  if (v.scriptURL != "") scriptParam = 'transcripts:[{url:"' + v.scriptURL + '", srcLang:"English",type:"Transcript"}]'

  if (v.expirationDate != "") {
    expriationDateParam = `expirationDate:"` + new Date(v.expirationDate).toISOString() + `"`
  }
  let lmsParam = `lms:{`
  lmsParam += `lmsSearch:` + v.lmsSearch || false
  lmsParam += `, assignableLearningObj:` + v.assignableLearningObj || false
  lmsParam += `, catalog:"` + (v.groupIndirectDealer && v.groupServicePartners ? "General" : "Restricted")
  lmsParam += `"}`
  console.log(tag)
  const param = `mutation AddAudio {
             createAudio(input:{
                id:"` + v.id + `"
                title: "` + cleanUpTextField(v.title) + `"
                description: "` + encodeURIComponent(v.description) + `",
                thumbnail: "${poster}",
                primaryAuthor: "` + v.primaryAuthor + `"
                secondaryAuthor: "` + v.secondaryAuthor + `"
                audioURL: "${audio}",
                writer:"` + v.writer + `",
                creative:"` + v.creative + `",
                includeInClipsSearch: ` + v.includeInClipsSearch + `
                allowDownload: ` + v.allowDownload + `
                allowTrainerDownload: ` + v.allowTrainerDownload + `
                audienceList:[ ` + group + `]
                keywords: [ ` + cleanUpTextField(tag) + ` ]
                publishDate:"` + new Date(v.publishScheduleDate).toISOString() + `"
                publishType:"` + v.publishType + `"
                expiration:` + v.expiration + `
                audience:"` + v.audience + `"
                sourceURL:"` + v.sourceURL + `"
                audioState: "TRANSCODING"
                includeInfederatedSearch:${v.includeInfederatedSearch}
                ` + businessPartnerParam + `
                ` + captionParam + `
                ` + expriationDateParam + `
                ` + groupParam + `
                ` + scriptParam + `
                ` + lmsParam + `
              })
              {
                id
                title
                description
                primaryAuthor
                owner
                createdAt
              }
            }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );

  if (v.subcollectionID != null || v.subcollectionID !== undefined) addAudioToCollection(v.id, v.subcollectionID, groupParam)
}

export const editAudio = async (v, id, captionArray, scriptURL, scriptLanguage) => {
  let assignableLearningObj
  if (v.assignableLearningObj == undefined) { assignableLearningObj = false }
  else {
    assignableLearningObj = v.assignableLearningObj
  }

  let tag = ''
  let group = '"Administrators",'
  let businessPartnerParam = ''
  let scriptParam = ''

  let expriationDateParam = ""
  for (let value of v.keywords) {
    tag += `"` + value + `",`
  }

  for (let value of v.audienceList) {
    group += `"` + value + `",`
  }
  if (!v.keywords.includes('trainerdownload')) {
    if (v.allowTrainerDownload) tag += `"trainerdownload"`
  }

  if (v.businessPartner != '') {
    businessPartnerParam = `businessPartner:"` + v.businessPartner + `",`
  }

  let videoState = v.videoState;
  let captionParam = ''
  let captionContent = ""
  if (captionArray.length > 0) {

    captionArray.forEach(element =>
      captionContent += `{
                      file: "${element.file}"
                      language: "${element.language}",
                      type: "${element.type}"
                 }`
    )

  }
  captionParam = `captions: [${captionContent}]`
  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  if (v.groupIndirectDealer) groupCanAccess += `"Indirect_Dealer",`
  if (v.groupServicePartners) groupCanAccess += `"Service_Partners",`
  if (v.groupVendedCare) groupCanAccess += `"Vended_care",`
  if (v.groupConsumerIndirect) groupCanAccess += `"Consumer_Indirect",`
  if (v.groupBusinessIndirect) groupCanAccess += `"Business_Indirect",`
  if (v.groupTelesales) groupCanAccess += `"Telesales",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`
  if (scriptURL != null && scriptURL != "") {
    scriptParam = 'transcripts:[{url:"' + scriptURL + '", srcLang:"' + scriptLanguage + '",type:"Transcript"}]'

  }
  let lmsParam = `lms:{`
  lmsParam += `lmsSearch:` + v.lmsSearch || false
  lmsParam += `, assignableLearningObj:` + assignableLearningObj || false
  lmsParam += `, catalog:"` + (v.groupIndirectDealer && v.groupServicePartners ? "General" : "Restricted")
  lmsParam += `"}`

  console.log(scriptURL)
  //if (v.scriptURL != "") scriptParam = 'scriptURL:"' + v.scriptURL + '"'

  if (v.expirationDate != "" && v.expirationDate != null) {
    expriationDateParam = `expirationDate:"` + new Date(v.expirationDate).toISOString() + `"`
  }



  const userInfo = Cache.getItem('userInfo')
  const param = `mutation updateAudio {
          updateAudio(input:{
               id:"` + v.id + `"
                title: "` + cleanUpTextField(v.title) + `"
                description: "` + cleanUpTextField(v.description) + `",
                primaryAuthor: "` + v.primaryAuthor + `"
                secondaryAuthor: "` + v.secondaryAuthor + `"
                writer:"` + v.writer + `",
                creative:"` + v.creative + `",
                includeInClipsSearch: ` + v.includeInClipsSearch + `
                allowDownload: ` + v.allowDownload + `
                allowTrainerDownload: ` + v.allowTrainerDownload + `
                keywords: [ ` + cleanUpTextField(tag) + ` ]
                publishDate:"` + new Date(v.publishScheduleDate).toISOString() + `"
                publishType:"` + v.publishType + `"
                expiration:` + v.expiration + `
                audience:"` + v.audience + `"
                includeInfederatedSearch:${v.includeInfederatedSearch}
                ` + businessPartnerParam + `
                ` + captionParam + `
                ` + expriationDateParam + `
                ` + groupParam + `
                ` + scriptParam + `
                ` + lmsParam + `
          })
          {
            id
            title
          }
        } `
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  if (v.subcollectionID != null || v.subcollectionID !== undefined) addAudioToCollection(v.id, v.subcollectionID, groupParam)
  console.log(op)
  return op;
}

export const createPlaylist = async (v) => {
  const userInfo = Cache.getItem('userInfo')
  const param = `mutation CreatePlaylist {
              createPlaylist(input:{
                id:"` + v.id + `"
                title:"` + v.title + `"
                description:"` + encodeURIComponent(v.description) + `"
                playlistUserId: "` + userInfo.id + `"
                   groupsCanAccess: ["${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators"]
              })
              {
                id
                title
                description
                createdAt
              }
            } `
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const addVideoToPlaylist = async (v, playlistid) => {
  console.log(v)
  const param = `mutation AddVideoToPlaylist {
          createVideo(input:{
            allowTrainerDownload:` + v.allowTrainerDownload + `
            videoId:"` + v.id + `"
            title: "` + v.video_title + `"
            description: "` + v.video_desc + `",
            thumbnail: "` + v.poster_image_loc + `",
            videoPlaylistId:"` + playlistid + `",
            videoDuration:"` + v.videoDuration + `",
            groupsCanAccess: ["${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators"]
          })
          {
            videoId
          }
        } `
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const addAudioToPlaylist = async (v, playlistid) => {
  console.log(v)
  let atd = v.allowTrainerDownload === undefined ? false : v.allowTrainerDownload;
  const param = `mutation addAudioToPlaylist {
                  createAudio(input:{
                    allowTrainerDownload:` + atd + `
                    audioId : "${v.id}"
                    title : "${v.video_title}"
                    description: "${v.video_desc}"
                    audioPlaylistId: "${playlistid}"
                    audioDuration: "${v.videoDuration}"
                    groupsCanAccess : ["Administrators","${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}"]
                    thumbnail:"${v.poster_image_loc}"
                  })
                  {
                    id
                  }
                } `
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const addAudioToPodcast = async (vid, podcast) => {
  console.log(vid)
  const param = `mutation AddAudioToPodcast {
    createPodcastAudio(input:
    {
    podcastAudioAudioId: "${vid.id}",
    groupsCanAccess: ["Administrators","${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}"]
    podcastAudioPodcastshowId: "${podcast}"})
    {
    id



    }
    }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const addVideoToPodcast = async (vid, podcast) => {

  const param = `mutation addVideoToPodcastShow {
    addVideoToPodcastShow(input:{
        videoId: "${vid.id}"
        podcastId:"${podcast}"
      })
    }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const removePodcast = async (id) => {

  const param = `mutation deletePodcastByID {
                    deletePodcastShows(input:{
                      id:"${id}"
                    })
                    {
                      id
                    }
                  } `
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const removeVideoFromPlaylist = async (v, playlistid) => {

  const param = `mutation DeleteVideoFromPlaylist {
                  deleteVideoFromPlaylist(input:{
                    videoId:"` + v + `"
                    playlistId:"` + playlistid + `"
                  })
                  {
                    videoId
                  }
                }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const removeAudioFromPlaylist = async (v, playlistid) => {

  const param = ` mutation DeleteAudioFromPlaylist {

                  deleteAudioFromPlaylist(input:{

                    audioId:"${v}"

                    playlistId:"${playlistid}"

                  })

                  {

                    videoId

                  }

                }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
}

export const searchAudioByID = async (keyword) => {
  const param = `query SearchAudio {

  searchForAudio(id:"${keyword}")

}`

  const op = await API.graphql(
    graphqlOperation(param)
  );

  return op;
}

export const searchAudioByKeywords = async (keyword) => {
  const param = `query searchAudios {
  searchAudioByKeyword(keyword:"${keyword}")
  {
    items{
      id
      title
      audioURL
      thumbnail
      audioDuration
      sourceURL
      createdAt
    }
  }
}`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const DeleteAudioByID = async (id) => {
  const param = `mutation deleteAudioByID {
                    deleteAudio(input:{
                      id:"${id}"
                    })
                    {
                      id
                    }
                  }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const searchVideoByID = async (keyword) => {
  const param = `query SearchVideos {
              searchVideos(filter:{
                id:{
                  matchPhrase:"` + keyword + `"
                }
              })
              {
                items{
                id
                title
                description
                thumbnail
                 captions{
                  file
                  type
                  language
                }
                transcripts {
                    url
                    srcLang
                    type
                  }
                audienceList
                groupsCanAccess
                videoViews
                createdAt
                primaryAuthor
                secondaryAuthor
                audience
                includeInClipsSearch
                videoDuration
                allowDownload
                keywords
                publishDate
                publishType
                expiration
                expirationDate
                studio
                 aspectRation
                compression
                writer
                creative
                businessPartner
                videoType
                includeInfederatedSearch
                allowTrainerDownload
                }
              }
            }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const searchVideoByID2 = async (vid) => {
  const param = `query SearchVideo {

  searchForAVideo(id:"${vid}")

}`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const searchVideoByPrimaryAuthor = async () => {
  const param = `query SearchVideos {
              searchVideos(filter:{
                primaryAuthor:{
                  matchPhrase:"` + userInfo.id + `"
                }
              })
              {
                items{
                  id
                  title
                  transcripts {
                    url
                    srcLang
                    type
                  }
                  audience
                  description
                  thumbnail
                  videoDuration
                  groupsCanAccess
                  videoViews
                  createdAt
                }
              }
            }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const searchVideoBySecondaryAuthor = async (author) => {
  console.log(userInfo.id)
  const param = `query SearchVideos {
              searchVideos(filter:{
               secondaryAuthor:{
                  matchPhrase:"` + userInfo.id + `"
                }
              }limit:100)
              {
                items{
                  id
                  audience
                  title
                  description
                  thumbnail
                  videoDuration
                  groupsCanAccess
                  videoViews
                  createdAt
                  primaryAuthor
                  secondaryAuthor
                  videoState
                  videoURL
                  captions{
                    file
                  }
                }
              }
            }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const searchAudioByOwner = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query GetAudiosByAuthor {

              searchAudios(filter:{
                primaryAuthor:{
                  matchPhrase:"` + userInfo.id + `"
                }
              })
              {
                items{
                  id
                  title
                  description
                  thumbnail
                 audioDuration
                  groupsCanAccess
                  expirationDate
                 audioViews
                  createdAt
                  updatedAt
                   allowTrainerDownload
                   audioState
                }
              }
            }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const searchVideoByOwner = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query SearchVideos {
              searchVideos(filter:{
                owner:{
                  matchPhrase:"` + userInfo.id + `"
                }
                primaryAuthor:{
                  exists: true
                }
              }limit:1000)
              {
                items{
                  id
                  title
                  audience
                  description
                  thumbnail
                  videoURL
                  videoDuration
                  groupsCanAccess
                  expirationDate
                  videoViews
                  createdAt
                  updatedAt
                  videoURL
                  ASR {
                    transcript {
                      file
                      language
                      display
                      createdAt
                    }
                    captions {
                      file
                      language
                      display
                      createdAt
                    }
                  }
                  videoState
                  primaryAuthor
                  secondaryAuthor
                  allowTrainerDownload
                  captions{
                   file
                  }
                 transcripts {
                  url
                  srcLang
                  type
                  }
                  channels{
                    items{
                      channel{
                        id
                        title
                        description
                      }
                    }
                  }
                }
              }
            }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const checkMediaPlaylistChannel = async (id) => {
  const param = `query searchForVideos {
    checkForVideoInPlaylistChannel(id:"${id}")
    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const DeleteVideoByID = async (id, playlist, channel, type) => {
  let thisType = type || 'DELETE';
  console.log()
  playlist = playlist.map(el => '"' + el + '"')
  channel = channel.map(el => '"' + el + '"')
  console.log(channel)
  let thisPlaylist = playlist.join(',')
  let thisChannel = channel.join(',')
  console.log(thisChannel)
  const param = `mutation DeletePlaylistAndChannelVideos {
    deletePlaylistAndChannelVideos(input:{
    id: "${id}"
    playlistList:[${thisPlaylist}]
    channelList:[${thisChannel}]
    eventType:"${thisType}"
    })
    {
    id
    }
    }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const ArchiveVideoByID = async (id) => {
  //const now = new Date().toISOString();
  var tomorrow = new Date();
  var today = new Date();
  tomorrow.setDate(new Date().getDate() + 1)
  const param = `mutation editVideo {
              updateVideo(input:{
                id:"${id}"
                expiration:true
                expirationDate:"${tomorrow.toISOString()}"
                videoState:"ARCHIVED"
              })
              {
                id
                title
                expiration
                expirationDate
              }

            }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const ArchiveAudioByID = async (id) => {
  //const now = new Date().toISOString();
  var tomorrow = new Date();
  var today = new Date();
  tomorrow.setDate(new Date().getDate() + 1)
  const param = `mutation editAudio  {
              updateAudio(input:{
                id:"${id}"
                expiration:true
                expirationDate:"${tomorrow.toISOString()}"
                audioState:"ARCHIVED"
              })
              {
                id

              }

            }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const RestoreVideoByID = async (id) => {
  //const now = new Date().toISOString();
  var nextYear = new Date();
  var today = new Date();
  nextYear.setDate(new Date().getDate() + 365)
  const param = `mutation editVideo {
              updateVideo(input:{
                id:"${id}"
                expiration:false
                videoState:"ACTIVE"
                expirationDate:"${nextYear.toISOString()}"

              })
              {
                id
                title
                expiration
                expirationDate
              }

            }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const recordAudioListen = async (id, title, desc, poster, keywords) => {
  const param = `mutation createAudioWatch {
  createAudioWatch(input:{
    audioId:"${id}"
    title:"${title}"
    description:"${desc}"
    thumbnail:"${poster}"
  })
  {
    id
  }
  }`

  const op = await API.graphql(
    graphqlOperation(param)
  );


  let tag = ''
  for (let value of keywords) {
    tag += `"` + value + `",`
  }

  var data = JSON.stringify({
    "Event": {
      "itemId": `${id}`,
      "keywords": [`${tag}`]
    },
    "SessionId": `${generateID(10)}`,
    "EventType": "VIEW",
    "UserId": `${userInfo.id}`
  })


  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      console.log(this.responseText);
    }
    console.log(this.responseText)
  });


  getAuthorizeToken().then(function(response) {
      const token = JSON.parse(response);

      xhr.open("POST", `${process.env.REACT_APP_API_HOST}/events`, true);
      xhr.setRequestHeader("authorization", `Bearer ${token.access_token}`);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(data);
    })
    .catch(function(err) {
      console.error('Augh, there was an error!', err.statusText);
    });

}

export const recordWatch = async (id, title, desc, poster, keywords) => {
  const param = `mutation RecordVideoWatch {
              createVideoWatch(input:{
                 videoId:"` + id + `"
                title: "` + title + `"
                description: "` + desc + `",
                 thumbnail: "` + poster + `",
              })
              {
                id
                videoId
              }
            }`

  const op = await API.graphql(
    graphqlOperation(param)
  );


  let tag = ''
  for (let value of keywords) {
    tag += `"` + value + `",`
  }

  var data = JSON.stringify({
    "Event": {
      "itemId": `${id}`,
      "keywords": [`${tag}`]
    },
    "SessionId": `${generateID(10)}`,
    "EventType": "VIEW",
    "UserId": `${userInfo.id}`
  })


  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      console.log(this.responseText);
    }
    console.log(this.responseText)
  });


  getAuthorizeToken().then(function(response) {
      const token = JSON.parse(response);

      xhr.open("POST", `${process.env.REACT_APP_API_HOST}/events`, true);
      xhr.setRequestHeader("authorization", `Bearer ${token.access_token}`);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(data);
    })
    .catch(function(err) {
      console.error('Augh, there was an error!', err.statusText);
    });

}

export const updatePlaylist = async (p, videoArray) => {
  const param = `mutation UpdatePlaylist {
              updatePlaylist(input:{
                id:"` + p.id + `"
                title:"` + p.title + `"
                description: "` + encodeURIComponent(p.description) + `"
                videoArray:[${videoArray}]
              })
              {
                id
                title
                description
              }
            }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const reorderVideo = (video, order, key) => {

  //console.log(video)
  //console.log(order)
  video.sort(function(a, b) {
    let A, B;
    a.hasOwnProperty('audioId') ? A = a['audioId'] : A = a['videoId']
    b.hasOwnProperty('audioId') ? B = b['audioId'] : B = b['videoId']

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    else {
      return -1;
    }
  });
  return video;
}

export const createChannel = async (c, fv, ft, fc) => {
  let tag = ''
  let owner = ''
  let mut = ''
  let fparam = ''

  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  if (c.groupIndirectDealer) groupCanAccess += `"Indirect_Dealer",`
  if (c.groupServicePartners) groupCanAccess += `"Service_Partners",`
  if (c.groupBusinessIndirect) groupCanAccess += `"Business_Indirect",`
  if (c.groupTelesales) groupCanAccess += `"Telesales",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  if (fv != "") {
    fparam = `featureVideo:{`
    if (ft != '') fparam += `thumbnail:"${ft}",`
    fparam += `videoURL:"${fv}",`
    if (fc != '') fparam += `captionsURL:"${fc}",`
    if (c.featuredVideoTitle != '') fparam += `title:"${c.featuredVideoTitle}",`
    if (c.featuredVideoDesc != '') fparam += `description:"${c.featuredVideoDesc}",`
    fparam += `}`
  }
  let businessPartnerParam = ''
  for (let value of c.keywords) {
    tag += `"` + value + `",`
  }
  if (c.prescribedAudience.length > 0) {
    mut += `prescribedAudience: [`
    for (let value of c.prescribedAudience) {
      mut += `"${value}",`
    }
    mut += `]`
  }
  if (c.requiredAudience.length > 0) {
    mut += `requiredAudience: [`
    for (let value of c.requiredAudience) {
      mut += `"${value}",`
    }
    mut += `]`
  }

  if (c.secondaryAuthor != '') mut += `secondaryAuthor:"${c.secondaryAuthor}"`
  if (c.businessPartnerEmail != '') mut += `businessPartnerEmail:"${c.businessPartnerEmail}"`
  if (c.description != '') mut += `description:" ${c.description}"`

  const param = `mutation createChannel{
          createAChannel(input:{
            id:"` + c.id + `"
            title:"` + c.title + `"
            thumbnail:"` + c.thumbnail + `"
            primaryAuthor:"` + c.primaryAuthor + `"
            iconImage:"` + c.iconImage + `"
            keywords:[` + tag + `]
            channelStatus:"` + c.channelStatus + `"
            includeInClipsSearch:` + c.includeInClipsSearch + `
            ${mut}
            ${groupParam}
            ${fparam}
          })
          {
            id
          }
        }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const updateChannel = async (c, fv, ft, fc) => {
  let tag = ''
  let owner = ''
  let mut = ''
  let fparam = ''
  console.log(c)
  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  if (c.groupIndirectDealer) groupCanAccess += `"Indirect_Dealer",`
  if (c.groupServicePartners) groupCanAccess += `"Service_Partners",`
  if (c.groupBusinessIndirect) groupCanAccess += `"Business_Indirect",`
  if (c.groupTelesales) groupCanAccess += `"Telesales",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  if (fv != null) {
    fparam = `featureVideo:{`
    if (ft != '') fparam += `thumbnail:"${ft}",`
    fparam += `videoURL:"${fv}",`
    if (fc != '') fparam += `captionsURL:"${fc}",`
    if (c.featuredVideoTitle != '') fparam += `title:"${c.featuredVideoTitle}",`
    if (c.featuredVideoDesc != '') fparam += `description:"${c.featuredVideoDesc}",`
    fparam += `}`
  }

  let businessPartnerParam = ''

  for (let value of c.keywords) {
    console.log(value)
    tag += `"` + value + `",`
  }
  if (c.prescribedAudience != null) {
    if (c.prescribedAudience.length > 0) {
      mut += `prescribedAudience: [`
      for (let value of c.prescribedAudience) {
        mut += `"${value}",`
      }
      mut += `]`
    }
  }
  if (c.requiredAudience != null) {
    if (c.requiredAudience.length > 0) {
      mut += `requiredAudience: [`
      for (let value of c.requiredAudience) {
        mut += `"${value}",`
      }
      mut += `]`
    }
  }

  if (c.secondaryAuthor != '') mut += `secondaryAuthor:"${c.secondaryAuthor}"`
  if (c.businessPartnerEmail != '') mut += `businessPartnerEmail:"${c.businessPartnerEmail}"`
  if (c.description != '') mut += `description:" ${c.description}"`

  const param = `mutation UpdateChannel {
        updateAChannel(input:{
            id:"` + c.id + `"
            title:"` + c.title + `"
            thumbnail:"` + c.thumbnail + `"
            primaryAuthor:"` + c.primaryAuthor + `"
            secondaryAuthor:"` + c.secondaryAuthor + `"
            iconImage:"` + c.iconImage + `"
            keywords:[` + tag + `]
            channelStatus:"` + c.channelStatus + `"
            includeInClipsSearch:` + c.includeInClipsSearch + `
            ${mut}
            ${groupParam}
            ${fparam}
          })
          {
            id
          }
        }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getAllUsers = async (p) => {
  const param = `query searchUsers {
                  searchUsers(filter:{
                    email:{
                      exists: true
                    }
                  })
                  {
                    items{
                      id
                      givenName
                      familyName
                    }
                  }
                }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const updateVideoArt = async (id, type, artURL) => {
  console.log(artURL)
  let mut = ''
  switch (type) {
    case "poster":
      mut += `thumbnail: "${artURL}"`
      break;
    case "art":
      mut += `art:"${artURL}" `
      break;
    case "chapter":
      mut += `chapters: [{file: "${artURL}",language: "English",sourceName: "chapter.vtt" }]`
      break;
    case "transcript":
      mut += `transcripts:[{url:"${artURL}", srcLang:"English",type:"Transcript"}]`
      break;
    default:

      // code block
  }

  const param = `mutation editVideo {
              updateVideo(input:{
                id:"${id}",
                ${mut}
              })
              {
                id
                title
              }
            }`
  console.log(param)

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;

}

export const updateAudioArt = async (id, type, artURL) => {
  console.log(artURL)
  let mut = ''
  switch (type) {
    case "poster":
      mut += `thumbnail: "${artURL}"`
      break;
    case "art":
      mut += `art:"${artURL}" `
      break;
    default:
      // code block
  }

  const param = `mutation updateAudio {
              updateAudio(input:{
                id:"${id}",
                ${mut}
              })
              {
                id
                title
              }
            }`
  console.log(param)

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;

}

export const updatePodcastArt = async (id, type, artURL) => {
  console.log(artURL)
  let mut = ''
  switch (type) {
    case "poster":
      mut += `thumbnail: "${artURL}"`
      break;
    case "art":
      mut += `art:"${artURL}" `
      break;
    default:
      // code block
  }

  const param = `mutation updatePodcastShow {
    updatePodcastShows(input: {
      id: "${id}"
     ${mut}
    })
    {
      id
      art
    }
  }`
  console.log(param)

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;

}

export const editVideo = async (vid, id, captionArray, scriptURL, scriptLanguage, subID, lmsSearch) => {
  let assignableLearningObj
  if (lmsSearch == undefined) { lmsSearch = false }
  if (vid.assignableLearningObj == undefined) { assignableLearningObj = false }
  else {
    assignableLearningObj = vid.assignableLearningObj
  }
  let scriptParam = ''
  let tag = "",
    group = "",
    audience = ""
  
  if (vid.allowTrainerDownload){ 
    if (!vid.keywords.includes('trainerdownload')) {
   
      tag += `"trainerdownload"`
      tag += `"trainer_download"`
    }
  }
  else{
   
   
   vid.keywords = vid.keywords.filter(e => e !== 'trainerdownload'); // will return ['A', 'C']
    vid.keywords = vid.keywords.filter(e => e !== 'trainer_download');
     console.log(vid.keywords)
  }
if (vid.keywords != null)
    for (let value of vid.keywords) {
      tag += `"` + value + `",`
    }
  if (vid.groupCanAccess != null) {
    for (let value of vid.groupCanAccess) {
      group += `"` + value + `",`
    }
  }

  if (vid.audienceList != null) {
    for (let value of vid.audienceList) {
      audience += `"` + value + `",`
    }
  }

  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  if (vid.groupIndirectDealer) groupCanAccess += `"Indirect_Dealer",`
  if (vid.groupServicePartners) groupCanAccess += `"Service_Partners",`
  if (vid.groupBusinessIndirect) groupCanAccess += `"Business_Indirect",`
  if (vid.groupTelesales) groupCanAccess += `"Telesales",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  console.log(group)
  let mut = ''
  if (vid.title) mut += `title:"` + cleanUpTextField(vid.title) + '" '
  if (vid.primaryAuthor) mut += `primaryAuthor:"` + vid.primaryAuthor + '"  ' + `owner:"` + vid.primaryAuthor + '"  '
  if (vid.secondaryAuthor) mut += `secondaryAuthor:"` + vid.secondaryAuthor + '" '
  if (vid.compression) mut += `compression:"` + vid.compression + '" '
  if (vid.aspectRation) mut += `aspectRation:"` + vid.aspectRation + '" '
  if (vid.studio) mut += `studio:"` + vid.studio + '" '
  if (vid.writer) mut += `writer:"` + vid.writer + '" '
  if (vid.creative) mut += `creative:"` + vid.creative + '" '
  if (vid.businessPartner) mut += `businessPartner:"` + vid.businessPartner + '" '
  if (vid.videoType) mut += `videoType:"` + vid.videoType + '" '
  if (vid.description) mut += `description:"` + encodeURIComponent(vid.description.replace(/\"/g, "&#8220")) + '" '
  if (vid.keywords) mut += `keywords:[ ${tag} ] `
  if (vid.includeInClipsSearch) mut += `includeInClipsSearch:${vid.includeInClipsSearch} `
  if (vid.allowDownload) mut += `allowDownload: ${vid.allowDownload} `
  if (vid.allowTrainerDownload != null) mut += `allowTrainerDownload: ${vid.allowTrainerDownload} `
  if (vid.groupCanAccess != null) mut += `groupsCanAccess:[${ group}]`
  if (vid.audienceList) mut += `audienceList: [${audience}   ]`
  if (vid.tag) mut += `keywords: [ ${cleanUpTextField(tag)}] `
  if (vid.chapterURL) mut += `chapters: [{file: "${vid.chapterURL}",language: "English",sourceName: "chapter.vtt" }] `

  if (vid.publishScheduleDate && vid.publishType != "Immediate") mut += ` publishDate:"${new Date(vid.publishScheduleDate).toISOString()}" videoState:"SCHEDULED" `
  if (vid.publishType) {
    if (vid.publishType == 'Immediate') {
      mut += ` videoState:"ACTIVE" `
      mut += `publishDate:"${new Date().toISOString()}"`
    }

  }
  if (vid.expiration) mut += `expiration:${ vid.expiration} `
  if (vid.expirationDate) mut += `expirationDate:"${ new Date(vid.expirationDate).toISOString() }" `
  if (vid.studio) mut += `studio:"${ vid.studio }" `
  if (vid.includeInClipsSearch != null) mut += `includeInClipsSearch:${vid.includeInClipsSearch} `
  if (vid.allowDownload != null) mut += `allowDownload:${vid.allowDownload} `
  if (vid.includeInfederatedSearch != null) mut += `includeInfederatedSearch:${vid.includeInfederatedSearch} `
  if (vid.publishType != null) mut += `publishType:"${ vid.publishType}" `
  if (scriptURL != null && scriptURL != "") {
    scriptParam = 'transcripts:[{url:"' + scriptURL + '", srcLang:"' + scriptLanguage + '",type:"Transcript"}]'
    mut += scriptParam
  }

  if (vid.audience != null) mut += `audience:"${ vid.audience}" `
  mut += groupParam

  let captionParam = ''
  console.log(captionArray)
  let captionContent = ""
  if (captionArray.length > 0) {

    captionArray.forEach(element =>
      captionContent += `{
                      file: "${element.file}"
                      language: "${element.language}",
                      type: "${element.type}"
                 }`
    )

  }
  captionParam = `captions: [${captionContent}]`
  mut += captionParam

  let lmsParam = `lms:{`
  lmsParam += `lmsSearch:` + lmsSearch || false
  lmsParam += `, assignableLearningObj:` + assignableLearningObj || false
  lmsParam += `, catalog:"` + (vid.groupIndirectDealer && vid.groupServicePartners ? "General" : "Restricted")
  lmsParam += `"}`
  mut += lmsParam
  // console.log(mut)
  const param = `mutation editVideo {
              updateVideo(input:{
                id:"${id}"
                ${mut}
              })
              {
                id
                title
              }
            }`
  console.log(param)
  if (subID != null || subID !== undefined) addVideoToCollection(id, subID, groupParam)

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;

}

export const getChannelbyOwner = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query SearchChannels {
            searchChannels(filter:{
              owner:{
                matchPhrase:"` + userInfo.id + `"
              }
            })
            {
              items{
                id
                title
                description
               iconImage
              }
            }
          }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const getChannelbySecondaryAuthor = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query SearchChannels {
            searchChannels(filter:{
              secondaryAuthor:{
                matchPhrase:"` + userInfo.id + `"
              }
            })
            {
              items{
                id
                title
                description
               iconImage
              }
            }
          }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const deleteChannelbyID = async (id) => {
  console.log(id)
  const param = `mutation deleteChannel {
  deleteAChannel(input:{
    id:"${id}"
  })}`

  const op = await API.graphql(
    graphqlOperation(param)
  ).then(
    result => window.location.reload(false),

  );
  console.log(op)
  return op;
}

export const addVideoToChannel = async (videoID, channelID) => {

  const param = `mutation AddVideoToChannel {
        createChannelVideo(input:{
          channelVideoVideoId:"${videoID}"
          channelVideoChannelId:"${channelID}"
          groupsCanAccess: ["${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}", "Administrators"]
        })
        {
          id
        }
      }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const addAudioToChannel = async (videoID, channelID) => {

  const param = `mutation AddAudioToChannel {

         createChannelVideo(input:{

          channelVideoAudioId:"${videoID}"

          channelVideoChannelAudioId:"${channelID}"

          groupsCanAccess : ["Administrators","${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}"]

        })

        {

          id

        }

      }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const removeVideoFromChannel = async (videoID, channelID) => {

  const param = `mutation RemoveVideoFromChannel {
                    RemoveAVideoFromChannel(input:{
                      videoId:"${videoID}"
                      channelId:"${channelID}"
                    })
                    {
                      id
                    }
                  }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const removeAudioFromChannel = async (videoID, channelID) => {

  const param = ` mutation DeleteAudioFromChannel {

      RemoveAudioFromChannel(input:{

        audioId:"${videoID}"

        channelId:"${channelID}"

      })

      {

        id

      }

    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const removeEpisodeFromPodcast = async (episode, podcast) => {
  let param
  console.log(episode)
  if (episode.length == 12) {
    param = `mutation deleteVideoFromPodcast {
                  deleteVideoFromPodcastShow(input:{
                    videoId:"${episode}"
                    videoPodcastshowId:"${podcast}"
                  })
                  {
                    id
                  }
                }`
  }
  else if (episode.length == 16) {
    param = `mutation deleteAudioFromPodcast {
                  deleteAudioFromPodcastShow(input:{
                    audioId:"${episode}"
                    audioPodcastshowId:"${podcast}"
                  })
                  {
                    id
                  }
                }`
  }
  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const getChannelbyID = async (id) => {

  const param = `query SeachAChannel {
                searchChannel(id:"` + id + `"){
                  id
                  title
                  description
                  iconImage
                  subscribers
                  thumbnail
                  iconImage
                  audience
                  createdAt
                  videos{
                    items{
                      id
                      title
                      thumbnail
                    }
                  }
                }
              }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const getPlaylistbyOwner = async () => {

  const param = `query SearchPlaylists {
          searchPlaylists(filter:{
            owner:{
              matchPhrase:"` + userInfo.id + `"
            }
          }
          limit:100
          )
          {
            items{
              id
              title
              }
            nextToken
            }
          }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const searchPlaylistbyID = async (id) => {
  console.log('search playlist by id')
  const param = `query SearchPlaylists {



  searchPlaylists(filter:{

    id:{

            matchPhrase:"${id}"

          }

        })

        {

          items{

            id

            title

            description

            createdAt

            videoArray



            videos{

              items{
              allowTrainerDownload
              thumbnail

                videoId

                title

                description

                videoDuration

              }

            }

            audios {

              items {
                allowTrainerDownload
                audioId

                thumbnail

                title

                description

                audioDuration

              }

            }

          }

        }

      }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const updateGroup = async (oldGroup, newGroup) => {
  const userInfo = Cache.getItem('userInfo')
  //console.log(userInfo)
  const param = `mutation AddOrUpdateUserGroups {
                      addOrUpdateGroup(input:{
                        id:"${userInfo.id}"
                        previousGroup:"${oldGroup}"
                        newGroup:"${newGroup}"
                      })
                      {
                        id
                    addedUserToGroup
                      }
                    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const getHistory = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query SearchHistory {
                          searchHistorys(filter:{
                            historyUserId:{
                              matchPhrase:"${userInfo.id}"
                            }
                            videoId:{
                              exists: true
                            }
                          }
                          limit:200
                          )
                          {
                            items{
                              videoId
                              id
                              title
                              thumbnail
                              createdAt
                              like
                              favourite
                              }
                            }
                          }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const getLike = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query GetUserLikes{
          searchLikess(filter:{
            likesUserId:{
              matchPhrase:"${userInfo.id}"
            }
          }
          limit:100)
          {
            items{
            videoId
              title
              description
              thumbnail
              createdAt
              videoDuration
            }
          }
        }`
  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const getFavorite = async () => {
  const userInfo = Cache.getItem('userInfo')
  const param = `query GetUserFavorites {
                  searchFavouritess(filter:{
                    favouritesUserId:{
                      matchPhrase:"${userInfo.id}"
                    }
                  }
                  limit:100)
                  {
                    items{
                      videoId
                      title
                      description
                      thumbnail
                      createdAt
                      videoDuration


                    }
                  }
                }`
  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const searchChannelByKeyword = async (keyword) => {
  const param = `query SearchAllChannels{
  searchChannels(



filter:{



    channelStatus:{



      matchPhrase:"active"



    }



  title:{



    match:"${keyword}"



  }



  }) {



    items {



      id



      title



      description



      primaryAuthor



      thumbnail



      iconImage



      totalDuration



      subscribers



      channelStatus



      createdAt



       videos{



       items{



        video{



        id



        title



        thumbnail



        videoDuration



        createdAt



        videoViews



          }



        }



       }
      playlists{
        items{
          id
          title
          videos{
            items{
              id
              title
              description
            }
          }
        }
      }



    }



    nextToken



  }



}`
  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const updateProfilePhoto = async (photoURL) => {
  const userInfo = Cache.getItem('userInfo')
  const param = `mutation UpdateProfilePhoto {
      updateUser(input:{
        id:"${userInfo.id}"
        profilePhotoURL:"${photoURL}"
      })
      {
        id
        profilePhotoURL
      }
    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;
}

export const formatDate = (isoDate) => {
  let date = new Date(isoDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  let readableDate = (month + '/' + dt + '/' + year);
  return readableDate;

}

export const deleteFolderFromS3 = async (folderID) => {

  const param = `mutation DeleteFilesFromS3 {
      DeleteS3Object(id:"${folderID}")
    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  console.log(op)
  return op;

}

export const deletePlaylist = async (playlistID) => {

const param = `mutation DeletePlayList {
          deleteUserPlaylist(input:{
          id:"${playlistID}"
          videoId:"${process.env.REACT_APP_VIDEO_TABLE}"
          audioId:"${process.env.REACT_APP_AUDIO_TABLE}"
          })
          }`

const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;

}

export const formatDuration = (d) => {
  let duration = ""
  // d="01:20:40"

  if (d !== undefined && d !== null) {
    const tmp = d.split(':')
    let minute2
    const hour = parseInt(tmp[0], 10)
    const minute = parseInt(tmp[1], 10)
    const second = tmp[2]

    if (minute < 10) minute2 = "0" + minute;
    else minute2 = minute;

    if (tmp[0] != "00" || tmp[0] != "0") duration = `${hour}:${minute2}:${second}`
    if (tmp[0] == "00" || tmp[0] == "0") duration = `${minute}:${second}`
    if ((tmp[1] == "00" || tmp[1] == "0") && (tmp[0] == "00" || tmp[0] == "0")) duration = `:${second}`

  }

  // console.log('duration: ' + duration)
  return duration;

}

export const unformatDuration = (d) => {
  let sec_num = hmsToSecondsOnly2(d)
  console.log(sec_num)
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;

}

export function hmsToSecondsOnly2(str) {
  if (str != null) {
    str = str.slice(0, 8)
    var p = str.split(':'),
      s = 0,
      m = 1;
    if (p.length == 2) {
      if (p[0] == '') p[0] = 0;
    }
    if (p.length > 0) {
      while (p.length > 0) {
        console.log(p)
        s += m * parseInt(p.pop(), 10);
        m *= 60;
      }
      return s
    }
    else {
      return [1];
    }
  }
}

export function hmsToSecondsOnly(str) {
  if (str != null) {
    str = str.slice(0, 8)


    var p = str.split(':'),

      s = 0,
      m = 1;
    console.log(p)
    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
    }
    return s;
  }
}

export const getTotalDuration = (arr) => {
  const seconds = arr.reduce((a, b) => a + b, 0)
  const totalTime = new Date(seconds * 1000).toISOString().substr(11, 8)
  return totalTime;
}

export const removeUnneccesaryZero = (time) => {
  let duration = ''
  let tmp = time.split(':');
  if (tmp[0] != '00') return time
  duration += tmp[1] + ':' + tmp[2]
  return duration;
}

export const sortByDate = (arr) => {
  arr.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
  return arr;
}

export const searchChannelByID = async (id) => {
  const param = `query SearchChannels {
      searchChannels(filter:{
        id:{
          matchPhrase:"${id}"
        }
      })
      {
        items{
          id
          title
          playlists{
            items {
              id
              title
              videos{
                items{
                  id
                  title
                  description
                }
              }
            }
          }
          videos{
            items{
              id
              video{
                id
                title
              }
            }
          }
        }
      }
    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const addPlaylistToChannel = async (playlistID, channelID) => {
  const param = `mutation addPlaylistToChannel {
      addPlaylistToChannel(input:{
        playlistId:"${playlistID}"
        channelId:"${channelID}"
        groupsCanAccess:["Administrator"]
      })
      {
        id
      }
    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getPodcastByOwner = async (id) => {
  const param = `query GetPodcastShowsByOwner {

  searchPodcastShowss(filter:{
    owner:{
      matchPhrase:"${userInfo.id}"
    }
  })
  {
    items{
      id
      title
      description
      art
      bannerArt
      podcaster
      podcastStatus
      subscribers
      updatedAt
    }
  }
}`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getPodcast = async (id) => {
  const param = `query searchPodCast {
    searchPodcastShowss(filter: {id: {matchPhrase: "${id}"}}) {
    items {
    id
    title
    description
    podcaster
    podcastStatus
    createdAt
    subscribers
    art
    bannerArt
    tags
    includeInClipsSearch
    backupPodcaster
    podcastStatus
    groupsCanAccess
    audios {
    items {
      audio {
        id
        title
        thumbnail
        createdAt

        }
    }
    }
    videos {
    items {
    videoId
    title
    thumbnail
    createdAt

    }
    }
    }
    }
    }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const createPodcast = async (p) => {
  console.log(p)
  let group = '"Administrators",'
  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  let tag = ""
  let tmp
  if (p.tags != null)
    tmp = p.tags.split(',');
  for (let value of tmp) {
    tag += `"` + value + `",`
  }
  console.log('here')
  const param = `mutation createPodcastShow {

  createPodcastShows(input: {
    id: "${p.id}",
    title: "${p.title}",
    description: "${p.description}",
    art: "${p.thumbnail}",
    backupPodcaster: "${p.secondaryAuthor}",
    ${groupParam},
    bannerArt: "${p.iconImage}",
    category: "Podcast",
    podcastStatus: "${p.podcastStatus}",
    includeInClipsSearch: ${p.includeInClipsSearch},
    tags: [${tag}],

    podcaster: "${p.primaryAuthor}",

  }) {
    id
    title
  }
}`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const updatePodcast = async (p) => {
  console.log(p)
  let podcaster, backupPodcaster
  let groupCanAccess = `"${process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP}","Administrators",`
  let groupParam = `groupsCanAccess:[${groupCanAccess}]`

  let tag = ""
  let tmp
  if (p.tags != null)
    p.tags.toString()
  if (typeof(p.tags) == 'object') p.tags = p.tags.toString()
  tmp = p.tags.split(',');
  for (let value of tmp) {
    tag += `"` + value + `",`
  }
  let mut = ''


  const param = `mutation UpdatePodcast {
      updatePodcastShows(input:{
      id:"${p.id}"
      title: "${p.title}",
      description: "${p.description}",
      backupPodcaster: "${p.backupPodcaster}",
      ${groupParam},
      podcastStatus: "${p.podcastStatus}",
      includeInClipsSearch: ${p.includeInClipsSearch},
      tags: [${tag}],
      podcaster: "${p.podcaster}",
      })
      {
      id
      title
      }
      }`
  console.log(param)
  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const removePlaylistFromChannel = async (playlistID) => {
  const param = `mutation removePlaylistFromChannel {
      removePlaylistFromChannel(input:{
        playlistId:"${playlistID}"
      })
      {
        id
      }
    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getUserChannel = async (userID) => {
  const param = `query SearchUserSubscriptions {

    searchUsers(filter:{

    id:{

    matchPhrase:"${userID}"

    }

    })

    {

    items{

    id

    subscriptions{

    items{

    channel{

    id

    title

    description

    iconImage

    requiredAudience

    createdAt

    }

    }

    }

    }

    }

    }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getUserDetail = async (userID) => {
  const param = `query searchUsers {
  searchUsers(filter: {id: ${userID}) {
    items{
      id
      name
      company

    }
  }
}`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  // console.log(op)
  return op;
}

export const getUserDetailByID = async (userID) => {
  console.log(userID)
  const param = `query searchUsers {
                  searchUsers(filter:{
                   id:${userID}
                  })
                  {
                    items{
                      id
                      givenName
                      familyName
                      role
                    }
                  }
                }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  // console.log(op)
  return op;
}

export const getUserDetailByID2 = async (userID) => {
  console.log(userID)
  const param = `query searchUsers {
        searchUsers(filter:{
        id:{
        eq:"${userID}"
        }
        })
        {
        items{
        id
        givenName
        familyName
        email
        profilePhotoURL
        role
        }
        }
        }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  // console.log(op)
  return op;
}

export const updateCompanyRole = async (user, role) => {
  const param = `mutation addOrUpdatePartners{
         addOrUpdatePartners(input:{
          userId: "${user.id}"
          company:"${user.company}"
          audience:"${role}"
          groupsCanAccess:[
              "${role}"
            ]
        }
        )
          {
            company
            audience
            groupsCanAccess
          }
        }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const updateCompanyRole2 = async (user, role, role2) => {
  const param = `mutation addOrUpdatePartners{
         addOrUpdatePartners(input:{
          userId: "${user.id}"
          company:"${user.company}"
          audience:"${role}"
          groupsCanAccess:[
              "${role2}"
            ]
        }
        )
          {
            company
            audience
            groupsCanAccess
          }
        }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const checkCompanyRole = async (company) => {
  const param = `query SearchCompany {
          getPartners(id:"${company}"){
            id
            audience
            groupsCanAccess
          }
        }`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}

export const getRecommendation = async () => {
  var data = null;

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  return new Promise(function(resolve, reject) {
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4) {
        if (xhr.status >= 300) {
          reject("Error, status code = " + xhr.status)
        }
        else {

          resolve(xhr.responseText);
        }
      }
    }
    Auth.currentAuthenticatedUser({}).then(user => {
      let accessToken = user.signInUserSession.idToken
      let jwt = accessToken.getJwtToken()
      //  console.log(`myJwt:${jwt}`)

      //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
      xhr.open("GET", `${process.env.REACT_APP_API_HOST}/recommendations?userId=${userInfo.id}`, true);
      xhr.setRequestHeader("authorization", `Bearer ${jwt}`)
      xhr.send(data);
    });
    /*
    getAuthorizeToken().then(function(response) {
        const token = JSON.parse(response);

        xhr.open("GET", `${process.env.REACT_APP_API_HOST}/recommendations?userId=${userInfo.id}`, true);
        xhr.setRequestHeader("authorization", `Bearer ${token.access_token}`);
        xhr.send(data);
      })
      .catch(function(err) {
        console.error('Augh, there was an error!', err.statusText);
      });
      */
  });

}

export const getTrending = async () => {
  var data = null;

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  return new Promise(function(resolve, reject) {
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4) {
        if (xhr.status >= 300) {
          reject("Error, status code = " + xhr.status)
        }
        else {

          resolve(xhr.responseText);
        }
      }
    }
    Auth.currentAuthenticatedUser({}).then(user => {
      let accessToken = user.signInUserSession.idToken
      let jwt = accessToken.getJwtToken()
      //  console.log(`myJwt:${jwt}`)

      //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
      xhr.open("GET", `${process.env.REACT_APP_API_HOST}/trendingvideos`, true);
      xhr.setRequestHeader("authorization", `Bearer ${jwt}`)
      xhr.send(data);
    });
  });
}

const extractDashURL = (str) => {
  let url
  // Regular expression pattern
  const regex = /embedDashboard\('([^']+)'\);/g;

  // Array to store matches
  const matches = [];

  // Match the pattern in the string
  let match;
  while ((match = regex.exec(str)) !== null) {
    matches.push(match[1]);
  }

  console.log(matches);
  return matches[0]
}

export const getDashboard = async () => {
  var data = null;

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  return new Promise(function(resolve, reject) {
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4) {
        if (xhr.status >= 300) {
          reject("Error, status code = " + xhr.status)
        }
        else {
          console.log(xhr.responseText)

          window.open(extractDashURL(xhr.responseText), "_blank").focus(); // window.open + focus
          // resolve(xhr.responseText);
        }
      }
    }
    Auth.currentAuthenticatedUser({}).then(user => {
      console.log("send dashboard request3")
      let accessToken = user.signInUserSession.idToken
      let jwt = accessToken.getJwtToken()
      //  console.log(`myJwt:${jwt}`)

      //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
      // xhr.open("GET", `https://rwf6pvhlld.execute-api.us-east-1.amazonaws.com/dev/executive-dashboard`);
      xhr.open("GET", `${process.env.REACT_APP_DASHBOARD}`);
      xhr.setRequestHeader("authorization", `Bearer ${jwt}`)
     
      xhr.send(data);
    });
  });
}

export const getFeatureVideo = async (group, d) => {
  var data = null;

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  return new Promise(function(resolve, reject) {
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4) {
        if (xhr.status >= 300) {
          reject("Error, status code = " + xhr.status)
        }
        else {
          //console.log(xhr.responseText)
          resolve(xhr.responseText);
        }
      }
    }
    /*
    getAuthorizeToken().then(function(response) {
        const token = JSON.parse(response);
        //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
        xhr.open("GET", `${process.env.REACT_APP_API_HOST}/featuredvideo?useDefaultPlaylist=${d}&audience=${group}&featuredPlaylist=true`, true);
        xhr.setRequestHeader("authorization", `Bearer ${token.access_token}`);
        xhr.send(data);
      })
      .catch(function(err) {
        console.error('Augh, there was an error!', err.statusText);
      });
      */
    Auth.currentAuthenticatedUser({

    }).then(user => {
      let accessToken = user.signInUserSession.idToken
      console.log(accessToken)
      let jwt = accessToken.getJwtToken()
      console.log(`myJwt:${jwt}`)

      //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
      xhr.open("GET", `${process.env.REACT_APP_API_HOST}/featuredvideo?useDefaultPlaylist=${d}&audience=${group}&featuredPlaylist=true`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${jwt}`);
      xhr.send(data);
    });
  });
}

export const sendEvent = async (prop) => {
  let userEmail = userInfo.email
  //const keywords = prop.keywords.split(",")

  if (userEmail == null || userEmail === undefined) userEmail = 'Non-employee-Care-and-Sales_oa582157'

  let url = process.env.REACT_APP_HOME + `Watch?v=${prop.id}`
  const loc = document.location.href.split('#/')
  let pageContext = process.env.REACT_APP_HOME + loc[1]
  console.log(url)
  let token = JSON.parse(`{"id":"${prop.id}","type":"${prop.type}","userEmail":"${userEmail}","poster":"${prop.thumbnail}","title":"${prop.title}","url":"${url}","pageContext":"${pageContext}","INITIALIZED":${prop.init},"PLAYED":${prop.played},"COMPLETED":${prop.completed}, "duration":${prop.duration},"application":"clips","timecode":${prop.timecode},"percentWatched":${prop.pct},"date":"${new Date().toISOString()}","watershed":${prop.trackWatershed}}`)
  //console.log(typeof(token))
  token.keywords = prop.keywords;
  var data = JSON.stringify(token)
  console.log(data)

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  return new Promise(function(resolve, reject) {
    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        // console.log(this.responseText);
      }
      // console.log(this.responseText)
    });

    Auth.currentAuthenticatedUser({}).then(user => {
      let accessToken = user.signInUserSession.idToken
      let jwt = accessToken.getJwtToken()
      //  console.log(`myJwt:${jwt}`)

      //  https://api.clips.sprint.com/playlist?useDefaultPlaylist=false&audience=Consumer_Telesales&featuredPlaylist=true
      xhr.open("POST", `${process.env.REACT_APP_API_HOST}/userevents`, true);
      xhr.setRequestHeader("authorization", `Bearer ${jwt}`)
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(data);
    });
  });

}

async function getAuthorizeToken() {
  var data = "grant_type=client_credentials&scope=video%2Fupdate";

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;

  return new Promise(function(resolve, reject) {
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4) {
        if (xhr.status >= 300) {
          reject("Error, status code = " + xhr.status)
        }
        else {
          resolve(xhr.responseText);
        }
      }
    }
    xhr.open("POST", `${process.env.REACT_APP_AUTH_SERVER}`, );
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("authorization", `Basic ${process.env.REACT_APP_BASE64_ENCODE}`);
    xhr.send(data);
  });

}

export const searchChannel = async (ID) => {
  const param = `query SearchChannels {

  searchChannels(filter:{
    id:{
      matchPhrase:"${ID}"
    }
  })
  {
    items{
      id
      title
      description
      primaryAuthor
      secondaryAuthor
      businessPartnerEmail
      thumbnail
      iconImage
      totalDuration
      includeInClipsSearch
      subscribers
      keywords
      createdAt
      requiredAudience
      prescribedAudience
      channelStatus
      groupsCanAccess

       videos{
         items{
          video{
          id
          title
          thumbnail
          videoDuration
          createdAt
          videoViews
            }
          }
       }
       audios {

            items {

              id

              audio {

                id

                title
                audioDuration
                thumbnail
                createdAt

              }

            }

          }
       playlists{
        items {
          id
          title
          description
          createdAt
          videoArray
          videos{
            items{
            thumbnail
              id
              title
              description
              videoDuration
            }
          }
          audios{
            items{
            thumbnail
              id
              title
              description

            }
          }

        }
      }

    }

  }

}`

  const op = await API.graphql(
    graphqlOperation(param)
  );
  return op;
}


/////***********COLLECTION******************///////////////

export const getAllCollection = () => {

  const likeVid = `query GetCollections{
                searchCollections(filter:{
                  title:{
                    exists:true
                  }
                })
                {
                 items{
                  id
                  title
                  description
                  iconGraphic
                }
                }
              }`
  const op = API.graphql(
    graphqlOperation(likeVid)
  );
  return op;
}

export const addVideoToCollection = (id, subcollectionID, groupParam) => {

  const likeVid = `mutation addVideoToCollection {
                      addVideoToCollection(input:{
                        videoId:"${id}"
                        subcollectionId:"${subcollectionID}"
                        ${groupParam}

                      })
                    }`

  const op = API.graphql(
    graphqlOperation(likeVid)
  );
}

export const addAudioToCollection = (id, subcollectionID, groupParam) => {

  const likeVid = `mutation addAudioToCollection {
                      addAudioToCollection(input:{
                        audioId:"${id}"
                        subcollectionId:"${subcollectionID}"
                        ${groupParam}

                      })
                    }`


  /*
    const likeVid = `mutation addVideoToCollection {
          createChannelVideo(input:{
            channelVideoVideoId:"${id}"
            channelVideoSubCollectionId:"${subcollectionID}"
            ${groupParam}
          })
          {
            id
          }
        }`
        */
  const op = API.graphql(
    graphqlOperation(likeVid)
  );
}

export const removeVideoFromCollection = (id, cID) => {

  const likeVid = `mutation removeVideoFromCollection {
                    RemoveAVideoFromCollection(input:{
                      videoId:"${id}r"
                      collectionId:"${cID}"
                    })
                    {
                      id
                    }
                  }`
  const op = API.graphql(
    graphqlOperation(likeVid)
  );
  return op;
}

export const searchCollection = async (id) => {
  //console.log(id)
  const likeVid = `query GetCollectionVideos {

  searchCollections(filter:{

    id:{

      matchPhrase:"${id}"

    }

  })

  {

    items{

      id

      title

      iconGraphic
      bannerGraphic
      heroGraphic
      subCollection{

        items{

          id

          title

          channelVideo{

            items{
              
              video{

                id
                audience
                title
                allowTrainerDownload
                thumbnail
                videoViews
                videoState
                videoDuration
                createdAt

              }

            }

          }
          podcastAudio {

            items {

              audio {

                id
                audience
                thumbnail

                audioURL

              }

            }

          }

        }

      }

    }

  }

}`
  const op = await API.graphql(
    graphqlOperation(likeVid)
  );
  return op;
}

export const searchCollection3 = async (id) => {
  //console.log(id)
  const likeVid = `query GetCollectionVideos {

  searchCollections(filter:{

    id:{

      matchPhrase:"${id}"

    }

  })

  {

    items{

      id

      title

      iconGraphic
      bannerGraphic
      heroGraphic
      subCollection{

        items{

          id

          title

          

        }

      }

    }

  }

}`
  const op = await API.graphql(
    graphqlOperation(likeVid)
  );
  return op;
}

export const getDash = () => {

  var xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", "https://rwf6pvhlld.execute-api.us-east-1.amazonaws.com/dev/executive-dashboard", false); // false for synchronous request
  xmlHttp.send(null);
  return xmlHttp.responseText;

}

export const searchCollection2 = async (id) => {
  //console.log(id)
  const likeVid = `query searchCollections {

  searchCollections(filter:{

    id:{

      eq:"${id}"

    }

  })

  {

    items{
      id
      title
      iconGraphic
      bannerGraphic
      heroGraphic

      subCollection {

        items{

          id
          title

          channelVideo {

            items {

              video{
                audience
                id
                createdAt
                thumbnail
                title
                videoURL

                videoViews
                videoState
                videoDuration

              }

            }

          }

          podcastAudio {

            items {

              audio {
                createdAt
                id
                thumbnail
                title
                audioURL
                audioDuration
              }

            }

          }

        }

      }

    }

  }

}`
  const op = await API.graphql(
    graphqlOperation(likeVid)
  );
  return op;
}
