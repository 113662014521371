import React, { useEffect, useState, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ErrorIcon from '@material-ui/icons/Error';
import DuoIcon from '@material-ui/icons/Duo';
import DescriptionIcon from '@material-ui/icons/Description';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import CcIcon from '../images/iconJS/CC.js';
import VideoIcon from '../images/iconJS/Video.js';
import TranscriptIcon from '../images/iconJS/Transcript.js';
import ChapterIcon from '../images/iconJS/Chapter.js';
import ImageIcon from '@material-ui/icons/Image';
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: '#373737',
  },
  bar: {
    backgroundColor: '#8dc1c74',
  },
})(LinearProgress);
let icon
const baseStyle = {
  textAlign: 'center',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'solid',
  backgroundColor: '#fafafa',
  color: '#E20074',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight:200,
};

const activeStyle = {
  color:'white',
  borderColor: 'white',
  backgroundColor:'#6e0c39'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
 // marginTop: 16
 // width:100,
};

const thumb = {
  display: 'inline-flex',
 // borderRadius: 2,
  //border: '1px solid #eaeaea',
  marginBottom: 8,
  //marginRight: 8,
  width: 180,
  height: 100,
 // padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
 // overflow: 'hidden'
};
const thumbContainer = {
  textAlign: 'center',
  display: 'contents',
  minWidth: 0,
  maxWidth: '95%',
  overflow: 'hidden'
};
const fileNameStyle = {
 
  display: 'block',
  maxWidth:'inherit',
  //overflow: 'hidden',
  //height: 200,
};
const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

function Uploader(props) {
  const [files, setFiles] = useState([]);
  const[fileName, setFileName]= useState();
  const [over, setOver] = useState(false)
  const[rejcted, setRejected]=useState(false)
   const[isImage, setIsImage]=useState(props.type=='image')
   const[thisType, setThisType]=useState(props.type)
  const [uploaded, setUploaded] = useState(false)
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
     accept: getAccepted(props.type),
     maxSize: props.filesize,
     maxFiles:1,
     onDragOver:()=> {setOver(true)},
      onDragLeave:()=> {setOver(false)},
      onDrop: acceptedFiles => {
       setRejected(false)
       props.func(acceptedFiles);
       setUploaded(true)
      console.log(acceptedFiles)
       if(acceptedFiles[0]!=null) {
         setFileName(acceptedFiles[0].name)
         console.log(acceptedFiles)
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
           })));
         }
       else{ 
         setRejected(true)
         
       }
     
    }
  });
 //console.log(props.type)
  switch (props.type) {

    case 'video':
      icon = < VideoIcon/>;
    break;

    case 'image':
      icon = <ImageIcon/>;
    break;
    
    case 'artwork':
      icon = <ImageIcon/>;
    break;

    case 'subtitle':
      icon = <CcIcon/>;
    break;

    case 'script':
      icon = <TranscriptIcon/>;
    break;

    case 'chapter':
      icon = <ChapterIcon/>;
    break;

    case 'shownote':
      icon = <DuoIcon/>;
    break;

   
 
    default:
     
      break;
  }

  function getIcon (type){
     
      console.log(type)
      function isVideo () {
         icon=  <DuoIcon/>;
      }
       function isDoc () {
          icon= <DescriptionIcon/>;
      }
    function isAudio () {
        icon= <AudiotrackIcon/>;
      }
      function isImage () {
        setIsImage(true)
        
      } 
      var snacks = {
        'video': isVideo,
        'artwork':isImage,
        'subtitle':isDoc,
        'script':isDoc,
        'chapter':isDoc,
        'shownote':isDoc,
        'banner':isImage,
        'icon':isImage,
        'audio':isAudio,
      };
     // console.log(snacks[type])
       //return snacks[type]();
    }
    
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
    
      <div style={thumbInner} >
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  function getAccepted (type) {
      var thisType;
      function isImage () {
        
        return thisType = '.png,.jpg';
      }
      function isVideo () {
        return thisType= 'video/*';
      }
       function isVTT () {
        return thisType= '.vtt,.srt';
      }
      function isScript () {
        return thisType= '.html';
      }
       function isAudio () {
        return thisType= 'audio/*';
      }
      var snacks = {
        'image': isImage,
        'video': isVideo,
        'artwork':isImage,
        'subtitle':isVTT,
         'script':isScript,
        'chapter':isVTT,
        'shownote':isVTT,
        'banner':isImage,
        'icon':isImage,
        'audio':isAudio,
      };
      //console.log(snacks[type]())
      return snacks[type]();
    }
    
 useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
   
    //console.log(props.completed)
  }, [files, props.completed]);
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div>
    
    <div >
      <BorderLinearProgress variant="determinate" color="secondary" value={props.completed}/>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div>
        
        {icon}
           {(!over && !uploaded) && <p>Drag {props.type} files or click to browse</p>}
            {(over && !uploaded) && <span><CloudUploadIcon /><br/>Drop {props.type} files here</span>}
            {rejcted && <span><ErrorIcon /><br/>Error, please try another file </span>}
         </div>
     {uploaded &&
     <span  style={thumbContainer}>
    {isImage &&
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    }
    
    {!isImage &&
      <div>
        {getIcon(props.type)}
        </div>
      }
      <p style={fileNameStyle}>{fileName}</p>
      </span>
     }
      </div>
    </div>
    </div>
  );
}



export default Uploader;