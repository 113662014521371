import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Link, NavLink, BrowserRouter, withRouter, Redirect } from "react-router-dom";
import { Cache } from 'aws-amplify';
import getChannel from '../functions/getChannel.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from "react-router-dom";
import * as utils from '../functions/util.js';
import SupportIcon from '../images/iconJS/Support.js';
import RecommendIcon from '../images/iconJS/Recommended.js';
import PodcastIcon from '../images/iconJS/Podcast.js';
import CollectionIcon from '../images/iconJS/Collection.js';
import DataIcon from '../images/iconJS/Data.js';
import SpeakerIcon from '@material-ui/icons/Speaker';
import DashIcon from '@material-ui/icons/Assessment';
// COMPONENTS
import {
  IconButton,
  Drawer,
  List,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
}
from '@material-ui/core'
// ICONS
import {
  Settings,
  Home,
  AlarmAdd,
  TrendingUp,
  Whatshot,
  AccountCircle,
  Notifications,
  Star,
  ThumbUp,
  History,
  PlaylistPlay,
  Subscriptions,
  Message,
  Help,
  OndemandVideo,
  Equalizer,
  Schedule,
  PersonalVideo,
  VideoLibrary
}
from '@material-ui/icons';
//REDUX
import { connect } from 'react-redux'
import { toggleMenu } from '../action/menu'

const mapStateToProps = state => ({
  isMenuOpen: state.isMenuOpen,
  isLogged: state.isLogged,
  refresh: state.refresh,
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  router: state.router
})

//const color = 'rgba(255, 255, 255, 0.5)';
const dividerColor = '#404854';
const drawerWidth = 256;
const topOffset = 0;
const activeColor = '#E20074';
const useStyles = makeStyles(theme => ({
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: "#272727",
    zIndex: 0,
    //height: '100%',
  },
  drawerOpen: {
    boxShadow: 'inset 0px 11px 8px -10px #000',
    position: 'relative',
    borderRight: 'solid 1px rgba(230, 230, 230, .15)',
    top: topOffset,
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    boxShadow: 'inset 0px 11px 8px -10px #000',
    position: 'relative',
    top: topOffset,
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit - 4,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit - 4,
    },
  },
  roleTab: {
    minWidth: drawerWidth / 2,
  },
  list: {
    padding: 0,
    '& svg': {
      fontSize: 20,
      color: '#ACACAC',
    },
    '&:hover *': {
      textDecoration: 'none',
    },
  },
  item: {
    '&:hover $itemText': {
      color: 'white',
    },
    '&:hover': {
      color: 'white',
      background: '#1B1B1B',
    },
    "&$selected": {
      background: "black",
    },
    "&$selected svg": {
      color: "#E20074",
    },
    "&$selected $itemText": {
      //this fixes the item text underline color to yellow
      borderBottom: 'solid 2px #E20074',
      display:'inline'
    },

    "&$selected span": {
      color: 'white',
      //borderBottom: 'solid 2px #E20074',
    },
    "&:hover$selected": {
      color: '#E20074',
      backgroundColor: 'black',
      textDecoration: 'none',
    },
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  activeItem: {
    '& *': {
      color: activeColor,
      backgroundColor: 'black',
    },
  },
  header: {
    background: '#262f3d',
    boxShadow: `inset 0 -1px ${dividerColor}`,
  },
  unPaddedRight: {
    paddingRight: 0,
  },
  smallIcon: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    borderLeft: `1px solid ${dividerColor}`,
    borderRadius: 0,
    '&:hover': {
      background: 'none',
    },
  },
  itemIcon: {
    color: theme.palette.primary.contrastText,
    margin: 0,
    minWidth: 30,
  },
  categoryHeader: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  categoryHeaderNone: {
    display: 'none',
  },
  categoryHeaderText: {
    fontSize: 20,
    fontWeight: 100,
    color: theme.palette.common.white,
    marginBottom: 0,
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#ACACAC',
    width: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    //color:theme.palette.primary.contrastText,
    '&$textDense': {
      fontSize: 14,
      fontWeight: 500,

    },
    '&:hover': {

    }
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing.unit * .5,
    background: dividerColor,
  },
  selected: {
    backgroundColor: '#000',
  },
  '&:hover': {
    textDecoration: 'none',
  }
}));
//this.state = { selected: null };

let viewerMenu = [{
    children: [
      { id: 'Home', value: 'Home', icon: <Home />, link: "/" },
      //{ id: 'Recommended',value:'Recommended', icon: <Whatshot />, link:""},
      { id: 'Recently Added', value: 'Recent', icon: <AlarmAdd />, link: "/Recent" },
      { id: 'Trending', value: 'Trending', icon: <TrendingUp />, link: "/Trending" },
      { id: 'Recommended', value: 'Recommended', icon: <RecommendIcon />, link: "/Recommended" },
      { id: 'Collections', value: 'Collections', icon: <CollectionIcon/>, link: "/Collection" },
    ],
  },
  {
    id: 'Your Content',
    children: [
      { id: 'Favorites', value: 'Favorites', icon: <Star />, link: '/Favorites' },
      { id: 'Likes', value: 'Likes', icon: <ThumbUp />, link: '/Likes' },
      { id: 'History', value: 'History', icon: <History />, link: "/History" },
      { id: 'Playlists', value: 'Playlists', icon: <PlaylistPlay />, link: "/Playlists" },
      { id: 'Subscriptions', value: 'Subscriptions', icon: <Subscriptions />, link: "/Subscriptions" },
    ],
  },
  {
    id: 'Browse by Channel',
    children: [],
  },

  /*
  {
    children: [
      {
      { id: 'Feedback', icon: <Message /> },
      { id: 'Help', icon: <Help /> },
      }
    ],
  },
  */
];

let authorMenu = [

  {
    id: 'Your Content',
    children: [
      { id: 'Videos', value: 'authorVideos', icon: <VideoLibrary/>, link: '/authorVideos' },
      { id: 'Audio', value: 'authorAudio', icon: <SpeakerIcon />, link: "/authorAudio" },
      { id: 'Channels', value: 'authorChannel', icon: <PersonalVideo/>, link: '/authorChannel' },
      { id: 'Playlists', value: 'authorPlaylist', icon: <PlaylistPlay />, link: "/authorPlaylist" },
      { id: 'Podcasts', value: 'authorPodcast', icon: <PodcastIcon  />, link: "/authorPodcast" },
     

    ],
  },
  /*
     {

      },
      {
        children: [

      ],
    },
    */
];

const Navigator = (props) => {
 console.log(props)
  let url = (window.location.href).split('/#/')
  console.log(url[0])
  const dashURL =  url[0] + `?dashboard=true`
 // console.log(dashURL)
  //props.router.push('/authorVideos')


  let link = props.hash.split('/')

  let selectedLink = link[1];
  
  let selected = selectedLink;
  if (selected == '') selected = 'Home'
  let data
  const [finishLoading, setFinish] = useState(false);
  const [isUser, setUser] = useState(false);
 
  const [isPodcastAuthor, setPodcastAuthor] = useState(false);
  const [tab, setTab] = React.useState(0);
  const [thisuser, setThisUser] = useState();
  const [userInfo, setUserInfo] = useState();
  function handleChange(event, newValue) {
    setTab(newValue);

  }

  function gotoDefault(link) {

  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
 
  
  useEffect(() => {
    // console.log(props)
    //console.log('use effect nav')
   let tmpUserInfo
   Auth.currentAuthenticatedUser({

    }).then(user => {
     
     console.log(user)
  
      Cache.setItem('userInfo', user)
     setThisUser(user.username)
    utils.getUserDetailByID2(user.username).then(user=>{
  
       let tmp = user.data.searchUsers.items[0]
       tmpUserInfo= tmp
       setUserInfo(tmp)
          if (tmp  != null) {
          if (tmp.role == 'Viewer' || tmp.role == 'Trainer') setUser(true)
          if (tmp.role === 'Podcast-Author') setPodcastAuthor(true)
           }
            setFinish(true)
     })
    
   

   
    utils.getUserChannel(user.username).then(function(result) {
     
      console.log(result)
      let channel = [];
      viewerMenu[2].children.length = 0;
      data = result.data.searchUsers.items[0].subscriptions.items;
      data = data.filter(item=> item.channel!=null)
      
      /*
      const filteredArr = data.reduce((acc, current) => {
        const x = acc.find(item => item.channelId === current.channelId);
        if (!x) {
          return acc.concat([current]);
        }
        else {
          return acc;
        }
      }, []);
*/   
    
      let myChannels = data.filter(v => v.channel != null);
      Cache.removeItem('myChannel');
      viewerMenu[2].length = 0;
      Cache.setItem('myChannel', myChannels)
      for (var value of myChannels) {

        var obj = {};
        obj.id = value.channel.title;
        obj.value = "Channel" + "?id=" + value.channel.id
        obj.title = value.channel.title;
        obj.link = "/Channel"
        obj.query = "?id=" + value.channel.id

        viewerMenu[2].children.push(obj)
      }
       console.log(viewerMenu)
      if(viewerMenu[2].children.length==0){viewerMenu.splice(2,1)}
      if (!( tmpUserInfo.role == 'Podcast-Author'|| tmpUserInfo.role == 'Administrator')) {
        authorMenu[0].children = authorMenu[0].children.filter(x => { return x.id != 'Podcasts'/* && x.id != 'Audio';*/ })
        // authorMenu[0].children = authorMenu[0].children.filter(x => {return x.id != 'Audio';})
      }
      console.log(viewerMenu)
      /*
       if(!(userInfo.role =='Podcast-Author')){
        viewerMenu[0].children = viewerMenu[0].children.filter(x => {return x.id != 'Collections' })
       // authorMenu[0].children = authorMenu[0].children.filter(x => {return x.id != 'Audio';})
      }
      */

      if ( tmpUserInfo.role == 'Administrator' || tmpUserInfo.role == 'Channel-Author' ||  tmpUserInfo.role == 'Podcast-Author') {

        // authorMenu[0].children = authorMenu[0].children.filter(x => {return x.id != 'Audio';})
      }
      else {
        authorMenu[0].children = authorMenu[0].children.filter(x => { return x.id != 'Channels' })
      }


     

     
    });
      
    }).catch(e => {
   
    
    });
 
  
   
   
   
    //console.log(categories)
  }, [props.refresh]);

  function TabPanel(props) {
    const { children, value, index, menu, ...other } = props;
    if (finishLoading) {
      return (
        <div>
          <List role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                className={classes.list}
                style={{fontSize:14}}>
              
              {menu.map(({ id, children }) => (
                <React.Fragment key={id}> 
                  <ListItem className={ id ? classes.categoryHeader:classes.categoryHeaderNone} style={{paddingBottom: 0, paddingTop: 0}}>
                    <ListItemText
                      classes={{
                        primary: classes.categoryHeaderText,
                      }}
                    >
                    {id}
                    </ListItemText>
                  </ListItem>
                  {children.map((c) => (
                 
                    <Link to={{pathname: c.link, search: c.query,}} >
                      <ListItem

                       selected={selected === c.value}
                        button
                        dense
                        key={c.id}
                        onClick={() => setSelect(c.id)}
                        className={cx(classes.item)}
                        classes={{ selected: classes.selected }}
                      >
                        <ListItemIcon className={classes.itemIcon}>{c.icon}</ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.itemText,
                            textDense: classes.textDense,
                          }}
                          style={{width: 250,whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',}}
                        >
                          { c.id }
                        </ListItemText>
                      </ListItem>
                    </Link>
                 
                  
                  ))}
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))}
              
                <a href="https://tmobileusa.sharepoint.com/sites/clips" target="_blank">
                      <ListItem
                        button
                        dense
                        className={cx(classes.item)}
                        classes={{ selected: classes.selected }}
                      >
                        <ListItemIcon  className={classes.itemIcon} >  <SupportIcon fill={'#ccc'}/></ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.itemText,
                            textDense: classes.textDense, 
                          }}
                        >
                        Clips Help Center
                        </ListItemText>
                      </ListItem>
                    </a>
                    
                    {  (userInfo.role != 'Viewer') &&
                      <a >
                      <ListItem
                        button
                        dense
                        className={cx(classes.item)}
                        classes={{ selected: classes.selected }}
                        onClick={()=>utils.getDashboard()}
                      >
                        <ListItemIcon  className={classes.itemIcon} >  <DashIcon fill={'#ccc'}/></ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.itemText,
                            textDense: classes.textDense,
                          }}
                        >
                        Dashboard
                        </ListItemText>
                      </ListItem>
                    </a>
                    }
                 
                      {  (userInfo.role != 'Viewer') &&
                        <Link to={{pathname: "reporting",}} >
                      <ListItem
                        selected={selected === "reporting"}
                      
                      
                        onClick={() => setSelect("reporting")}
                        button
                        dense
                        className={cx(classes.item)}
                        classes={{ selected: classes.selected }}
                      >
                        <ListItemIcon  className={classes.itemIcon} >  <DataIcon fill={'#ccc'}/></ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.itemText,
                            textDense: classes.textDense,
                          }}
                        >
                        Data & Reporting
                        </ListItemText>
                      </ListItem>
                      </Link>
                   
                    }
            </List>
        
            </div>
      )
    }
    else return null

  }

  const [selectedID, setSelect] = useState('Home');
  const classes = useStyles();
  let open = !props.isMenuOpen;

  function updateSelected(selectedID) {
    selected = selectedID;
  }


  return (
    <Drawer variant="persistent"
        className={cx(classes.paperHeight, classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: cx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
          }}
       open={open}
        style={{ minHeight:'100%',height: props.height+'px'}}
      >

     <AppBar position="static" >
      {!isUser &&
       <Tabs value={tab} onChange={handleChange} variant="fullWidth"   >
          <Tab label="Viewer" className={classes.roleTab} {...a11yProps(0)}/>
          <Tab label="Author"  className={classes.roleTab}   {...a11yProps(1)}/>
        </Tabs>
      }
      </AppBar>
      <TabPanel value={tab} index={0} menu={viewerMenu}></TabPanel>
      <TabPanel value={tab} index={1} menu={authorMenu}></TabPanel>


      </Drawer>
  );

}


export default connect(mapStateToProps, null)(Navigator);
